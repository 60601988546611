import Header from "../Components/header";
import React, { Component } from "react";
import LandingContent from "../Components/signUp/landingContent";
import PartnerForm from "../Components/signUp/partnerForm";
import LandingInfo from "../Components/signUp/landingInfo";
import Footer from "../Components/footer";
import NextForm from "../Components/signUp/nextForm";
import Confirmation from "../Components/signUp/verification";
import LoginForm from "../Components/loginForm";
import { openNotificationWithIcon, postModule } from "../utils/apiService";

class Login extends Component {
  state = {
    email: "",
    password: ""
  };
  changeHandler(key, value) {
    const updatedData = this.state;
    updatedData[key] = value;
    this.setState(updatedData);
  }
  logHandler(e) {
    e.preventDefault();
    let updateState = this.state
    updateState["email"] = updateState.email.toLowerCase()
    postModule({ module: "auth/login", data: { ...this.state } }).then(
      (rep) => {
        if (rep.status === 200) {
          localStorage.setItem("token", rep.data.accessToken);
          window.location.reload();
        } else {
          return openNotificationWithIcon(
            "warning",
            "Wrong",
            "please check your inputs"
          );
        }
      }
    );
  }

  render() {
    return (
      <>
        <div class="partner-page-container">
          <div class="partner-landing">
            <div class="container">
              <Header />
              <div className="partner-landing-content">
                <LandingContent />
                <LoginForm
                  logHandler={this.logHandler.bind(this)}
                  onChange={this.changeHandler.bind(this)}
                />
              </div>
            </div>
          </div>
          <LandingInfo />
        </div>
        <Footer />
      </>
    );
  }
}

export default Login;
