import React, { Component } from "react";
import Footer from "../Components/footer";
import LoggedHeader from "../Components/loggedHeader";
import Nav from "../Components/nav";
import Reservation from "../Components/reservations";
import { getModule, postModule } from "../utils/apiService";

class Reservations extends Component {
  componentDidMount() {

    getModule({module :"rooms/reservations"}).then(rsp => console.log(rsp.data))
   
  }
  render() {
    return (
      <>
        <div class="accueil-container">
          <LoggedHeader />
          <Nav />
          <div className="reservations-container">
            <div className="reservations">
              <div className="reservation-content">
                <div className="accordion">
                  <Reservation />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Reservations;
