import React from "react";
import { Link } from "react-router-dom";

const header = (props) => {
  return (
    <div className="partner-header">
      <Link to="/" className="logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="204.672"
          height="64.854"
          viewBox="0 0 204.672 64.854"
        >
          <g
            id="Group_12098"
            data-name="Group 12098"
            transform="translate(-21.04 -112.866)"
          >
            <g
              id="Group_11936"
              data-name="Group 11936"
              transform="translate(69.783 121.789)"
            >
              <g id="Group_11934" data-name="Group 11934">
                <path
                  id="Path_42786"
                  data-name="Path 42786"
                  d="M83.893,170.762v-.433c2.214,0,4.133,0,4.133-6.061v-33.4c0-6.062-1.919-6.062-4.133-6.062v-.433h7.823l11.118,45.71,11.07-45.71H120.3v.433c-2.213,0-2.213,0-2.213,6.062v33.339c0,6.123,0,6.123,2.213,6.123v.433H111.69v-.433c2.164,0,2.214-.5,2.214-7.3,0-7.794.147-28.823.147-37.236H113.9l-10.922,44.968H98.8L88.518,127.835H88.37v36.433c0,6.061,1.919,6.061,4.133,6.061v.433Z"
                  transform="translate(-83.893 -124.372)"
                  fill="#101539"
                />
                <path
                  id="Path_42787"
                  data-name="Path 42787"
                  d="M143.261,143.514c5.46,0,8.216,4.453,8.216,9.464,0,6.8-8.216,8.35-14.513,8.536.2,5.814,1.279,13.731,6.3,13.731,5.56,0,7.871-7.979,7.871-14.164h.344c0,6.494-2.312,14.6-8.216,14.6-6.838.063-10.774-5.875-10.774-16.7C132.487,149.267,136.718,143.514,143.261,143.514Zm-6.347,15.464c0,.619,0,1.36.05,2.1,7.773-.186,10.725-1.979,10.725-10.206,0-5.2-1.131-6.928-4.427-6.928C137.9,143.947,136.914,153.1,136.914,158.978Z"
                  transform="translate(-94.802 -128.669)"
                  fill="#101539"
                />
              </g>
              <g
                id="Group_11935"
                data-name="Group 11935"
                transform="translate(60.634)"
              >
                <path
                  id="Path_42788"
                  data-name="Path 42788"
                  d="M162.079,134.763l.984-10.391h27.255l.984,10.391h-.345c-.787-8.288-3.591-9.958-9.3-9.958h-2.9l.049,39.463c0,6.061,0,6.061,2.214,6.061v.433h-8.61v-.433c2.165,0,2.165-.434,2.214-6.061V124.8h-2.9c-5.707,0-8.511,1.67-9.3,9.958Z"
                  transform="translate(-162.079 -124.372)"
                  fill="#101539"
                />
                <path
                  id="Path_42789"
                  data-name="Path 42789"
                  d="M199.635,172.445v-.433c2.164,0,2.213-.434,2.213-6.061V148.013c0-5.629,0-6.061-2.164-6.061v-.433h6.346v24.432c0,6.061,0,6.061,2.214,6.061v.433Zm1.82-37.483c0-1.732,1.083-3.093,2.46-3.093s2.46,1.361,2.46,3.093-1.083,3.093-2.46,3.093S201.455,136.694,201.455,134.962Z"
                  transform="translate(-170.51 -126.055)"
                  fill="#101539"
                />
                <path
                  id="Path_42790"
                  data-name="Path 42790"
                  d="M211.371,174.609c2.164,0,2.214-.434,2.214-6.061V150.609c0-5.629,0-6.061-2.165-6.061v-.433h6l.344,6.309v.124c.934-4.392,3.788-7.114,6.691-7.114,4.23,0,6.592,2.722,7.379,8.289.689-5.134,3.739-8.289,6.937-8.289,5.215,0,7.576,4.144,7.626,12.742l.049,12.371c0,5.628.05,6.061,2.214,6.061v.433h-8.61v-.433c2.165,0,2.214,0,2.214-6.061V158.96c0-8.1-.64-14.536-4.28-14.6-3.2-.062-5.854,3.65-5.953,9.34.05.742.1,1.608.1,2.474l.049,12.371c0,5.628.05,6.061,2.214,6.061v.433h-8.609v-.433c2.164,0,2.262,0,2.213-6.061l-.05-9.588c-.049-8.1-.589-14.536-4.23-14.6-3.247-.062-5.953,3.773-5.953,9.588v14.6c0,6.061,0,6.061,2.214,6.061v.433h-8.61Z"
                  transform="translate(-173.145 -128.651)"
                  fill="#101539"
                />
                <path
                  id="Path_42791"
                  data-name="Path 42791"
                  d="M271.246,143.514c5.461,0,8.216,4.453,8.216,9.464,0,6.8-8.216,8.35-14.514,8.536.2,5.814,1.279,13.731,6.3,13.731,5.56,0,7.871-7.979,7.871-14.164h.345c0,6.494-2.313,14.6-8.216,14.6-6.838.063-10.774-5.875-10.774-16.7C260.472,149.267,264.7,143.514,271.246,143.514ZM264.9,158.978c0,.619,0,1.36.049,2.1,7.773-.186,10.725-1.979,10.725-10.206,0-5.2-1.132-6.928-4.428-6.928C265.883,143.947,264.9,153.1,264.9,158.978Z"
                  transform="translate(-184.167 -128.669)"
                  fill="#101539"
                />
              </g>
            </g>
            <g
              id="Group_11939"
              data-name="Group 11939"
              transform="translate(21.04 112.866)"
            >
              <g id="Group_11937" data-name="Group 11937">
                <path
                  id="Path_42792"
                  data-name="Path 42792"
                  d="M33.49,112.866l4.776,9.467,8.221-9.467Zm1.284.792h9.978l-6.311,7.267Z"
                  transform="translate(-23.835 -112.866)"
                  fill="#101539"
                />
                <path
                  id="Path_42793"
                  data-name="Path 42793"
                  d="M49.6,149.578l-.42-1.214.42-1.214.176-.51,6.945-20.093H40.271l7.035,20.108.178.508.42,1.2-.42,1.2-.178.507-7.035,20.109H56.72l-6.945-20.093Zm-8.213-22.24H55.611L48.76,147.153l-.219.635-.217-.62Zm0,42.053,6.937-19.831.217-.62.219.635,6.851,19.815Z"
                  transform="translate(-25.357 -115.937)"
                  fill="#101539"
                />
                <path
                  id="Path_42794"
                  data-name="Path 42794"
                  d="M48.645,113.666l-7.937,9.14H56.583Zm0,1.207,6.2,7.142H42.443Z"
                  transform="translate(-25.455 -113.046)"
                  fill="#101539"
                />
                <path
                  id="Path_42795"
                  data-name="Path 42795"
                  d="M51.638,112.866,59.8,122.26l4.837-9.395Zm7.989,7.994-6.255-7.2h9.964Z"
                  transform="translate(-27.909 -112.866)"
                  fill="#101539"
                />
                <path
                  id="Path_42796"
                  data-name="Path 42796"
                  d="M30.314,113.765l-8.545,9.063H34.887Zm-.2,1.363,3.486,6.91h-10Z"
                  transform="translate(-21.204 -113.068)"
                  fill="#101539"
                />
                <path
                  id="Path_42797"
                  data-name="Path 42797"
                  d="M74.559,126.547H60.382L53.208,147.3l-.369,1.068.369,1.067,7.173,20.75H74.559L54.2,148.364Zm-1.82,42.844H60.945L54,149.307ZM60.945,127.338H72.739L54,147.421Z"
                  transform="translate(-28.179 -115.937)"
                  fill="#101539"
                />
                <path
                  id="Path_42798"
                  data-name="Path 42798"
                  d="M42.378,147.31l-7.265-20.763H21.04L41.4,148.364,21.04,170.182H35.113l7.265-20.764.368-1.053ZM22.859,127.338H34.552l7.015,20.052Zm11.693,42.053H22.859l18.708-20.052Z"
                  transform="translate(-21.04 -115.937)"
                  fill="#101539"
                />
                <path
                  id="Path_42799"
                  data-name="Path 42799"
                  d="M67.743,113.761l-4.669,9.066H76.292Zm.192,1.356,6.525,6.92H64.371Z"
                  transform="translate(-30.476 -113.067)"
                  fill="#101539"
                />
              </g>
              <g
                id="Group_11938"
                data-name="Group 11938"
                transform="translate(0.565 55.093)"
              >
                <path
                  id="Path_42800"
                  data-name="Path 42800"
                  d="M38.266,184.286l-4.776,9.467h13Zm.175,1.409,6.311,7.267H34.774Z"
                  transform="translate(-24.4 -183.992)"
                  fill="#101539"
                />
                <path
                  id="Path_42801"
                  data-name="Path 42801"
                  d="M40.708,183.907l7.937,9.14,7.938-9.14Zm1.735.791H54.849l-6.2,7.143Z"
                  transform="translate(-26.021 -183.907)"
                  fill="#101539"
                />
                <path
                  id="Path_42802"
                  data-name="Path 42802"
                  d="M59.8,184.38l-8.158,9.395H64.632Zm-.168,1.4,3.708,7.2H53.372Z"
                  transform="translate(-28.474 -184.013)"
                  fill="#101539"
                />
                <path
                  id="Path_42803"
                  data-name="Path 42803"
                  d="M21.769,183.907l8.545,9.064,4.573-9.064Zm8.347,7.7L23.6,184.7h10Z"
                  transform="translate(-21.769 -183.907)"
                  fill="#101539"
                />
                <path
                  id="Path_42804"
                  data-name="Path 42804"
                  d="M63.074,183.907l4.669,9.068,8.549-9.068Zm1.3.791H74.459l-6.525,6.92Z"
                  transform="translate(-31.042 -183.907)"
                  fill="#101539"
                />
              </g>
            </g>
          </g>
        </svg>
        <i className="far fa-bars"></i>
      </Link>
      <div className="navbar">
        <div className="accueil">Accueil</div>
        <div className="nav-setting">
          <ul>
            <li className="lang">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.995"
                height="26.995"
                viewBox="0 0 26.995 26.995"
              >
                <path
                  id="world"
                  d="M13.5,0a13.5,13.5,0,1,0,13.5,13.5A13.5,13.5,0,0,0,13.5,0ZM25.231,13.062H20.01a17.349,17.349,0,0,0-1-5.458,14.224,14.224,0,0,0,3.253-1.913A11.7,11.7,0,0,1,25.231,13.062ZM13.016,25.229a13.878,13.878,0,0,1-3.549-4.587,13.5,13.5,0,0,1,3.6-.591v5.18Zm.961-23.463A14.058,14.058,0,0,1,17.866,7.1a13.467,13.467,0,0,1-3.934.714V1.763Zm1.451.148a11.724,11.724,0,0,1,6.238,3.147A13.321,13.321,0,0,1,18.681,6.8,15.313,15.313,0,0,0,15.428,1.914Zm-2.366-.151V7.814A13.49,13.49,0,0,1,9.128,7.1a14.058,14.058,0,0,1,3.889-5.334ZM8.313,6.8a13.354,13.354,0,0,1-2.984-1.74,11.721,11.721,0,0,1,6.238-3.147A15.3,15.3,0,0,0,8.313,6.8ZM8.8,7.9a14.306,14.306,0,0,0,4.263.783v4.376H7.855A16.476,16.476,0,0,1,8.8,7.9Zm4.263,6.029v5.247a14.291,14.291,0,0,0-3.953.67,16.358,16.358,0,0,1-1.254-5.916Zm-1.5,11.148a11.7,11.7,0,0,1-5.7-2.658,13.235,13.235,0,0,1,2.776-1.48A15.08,15.08,0,0,0,11.567,25.081Zm2.366.151v-5.18a13.5,13.5,0,0,1,3.6.591,13.89,13.89,0,0,1-3.549,4.587Zm4.418-4.289a13.341,13.341,0,0,1,2.776,1.48,11.7,11.7,0,0,1-5.7,2.658A15.1,15.1,0,0,0,18.351,20.943Zm-.466-1.094a14.3,14.3,0,0,0-3.953-.67V13.933h5.206A16.376,16.376,0,0,1,17.885,19.849Zm-3.953-6.787V8.686A14.316,14.316,0,0,0,18.2,7.9a16.526,16.526,0,0,1,.944,5.159H13.933Zm-9.2-7.371A14.237,14.237,0,0,0,7.984,7.6a17.349,17.349,0,0,0-1,5.458H1.763A11.694,11.694,0,0,1,4.732,5.691ZM1.763,13.933H6.985a17.176,17.176,0,0,0,1.309,6.215,14.133,14.133,0,0,0-3.081,1.681A11.724,11.724,0,0,1,1.763,13.933Zm20.019,7.9A14.156,14.156,0,0,0,18.7,20.147a17.176,17.176,0,0,0,1.31-6.215h5.221A11.724,11.724,0,0,1,21.782,21.828Z"
                  fill="#333"
                />
              </svg>
              <span>FR</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default header;
