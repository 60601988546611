import Orders from "./orders";
import React, { useEffect, useState } from "react";
import { getModule, postModule } from "../../utils/apiService";

const Reservation = (props) => {
  const [reservations, setReservations] = useState([]);
  useEffect(() => {
    getModule( {module : "rooms/reservations" }).then(rsp => setReservations(rsp.data) )

  },[])
 
  return <Orders
  reservations={reservations}
  
/>;
};

export default Reservation;