import React from "react";
import { NavLink, Link } from "react-router-dom";
import './nav.css'
const nav = (props) => {

  const displayNav = () => {
    document.querySelector('nav').classList.toggle('display')
  }
  return (
    <nav>
      <div className="nav-container">
        <NavLink to="/" >
          Vos Services
        </NavLink>
        <NavLink to="/reservations">
          Vos Réservations
        </NavLink>
        <NavLink to="/avis">
          Avis
        </NavLink>
        <NavLink to="/shop">
          Vos Shop
        </NavLink>
      { /*  <NavLink to="/stats">
          Statistiques
  </NavLink> */}
      </div>
      <svg onClick={() => displayNav()} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
        <path fillRule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
      </svg>
    </nav>
  );
};

export default nav;
