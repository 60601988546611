import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASEAPI, openNotificationWithIcon, postModule } from '../../utils/apiService'

const OpenCloseSelect = (props) => {

    const [openClose, setOpenClose] = useState({})
    //add zero to the minute ex:13:30 or hour ex: 08:30

    const addZero = (time) => {
        if (time < 10) {
            time = '0' + time
        }
        return time
    }
    useEffect(() => {

        setOpenClose({
            open: props.open,
            close: props.close
        })
        //first the prop is empty
    }, [props])

    const handleOpenClose = (e) => {
       e.preventDefault()
       const token = localStorage.getItem("token");

       axios.patch( BASEAPI + "/rooms/working-hours/" + props.id ,   {
        "workingHours": [
            {
                "dayOfWeek": props.day,
        "isAvailable": true,
        "startTime": openClose.open,
        "endTime": openClose.close
            }
          ]
        
      
       } ,  {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      }  ).then(rsp =>{
          if(rsp.status ===200) {
              openNotificationWithIcon( "success",
              "success",
              "Day Has been Updated Succefully")
          }
    
    })
      
    }

    return (
        <>
            <select value={openClose.open}
                onChange={e => setOpenClose({ ...openClose, open: e.target.value })}
                name="open"
            >
                <option value="opening...">Ouverture</option>
                <option value="08:00">08:00</option>
                <option value="08:30">08:30</option>
                <option value="09:00">09:00</option>
                <option value="09:30">09:30</option>
                <option value="10:00">10:00</option>
                <option value="10:30">10:30</option>
                <option value="11:00">11:00</option>
                <option value="11:30">11:30</option>
                <option value="12:00">12:00</option>
                <option value="12:30">12:30</option>
                <option value="13:00">13:00</option>
                <option value="13:30">13:30</option>
                <option value="14:00">14:00</option>
            </select>

            <select name="close" value={openClose.close}
                onChange={e => setOpenClose({ ...openClose, close: e.target.value })}
            >
                <option value="closing...">Fermeture</option>
                <option value="13:00">13:00</option>
                <option value="13:30">13:30</option>
                <option value="14:00">14:00</option>
                <option value="14:30">14:30</option>
                <option value="15:00">15:00</option>
                <option value="15:30">15:30</option>
                <option value="16:00">16:00</option>
                <option value="16:30">16:30</option>
                <option value="17:00">17:00</option>
                <option value="17:30">17:30</option>
                <option value="18:00">18:00</option>
                <option value="18:30">18:30</option>
                <option value="19:00">19:00</option>
                <option value="19:30">19:30</option>
                <option value="20:00">20:00</option>
                <option value="20:30">20:30</option>
                <option value="21:00">21:00</option>
                <option value="21:30">21:30</option>
                <option value="22:00">22:00</option>
                <option value="22:30">22:30</option>
                <option value="23:00">23:00</option>
            </select>
            <button onClick={(e) => handleOpenClose(e)}>
            </button>
        </>
    )
}

export default OpenCloseSelect
