import Signup from "./Containers/signup";
import Service from "./Containers/Services";
import Reservations from "./Containers/Reservations";
import Avis from "./Containers/Avis";
import ShopContainer from "./Containers/shop";
import Statiques from "./Containers/Statiques";
import Login from "./Containers/Login";
import "react-notifications-component/dist/theme.css";
import ReactNotification from "react-notifications-component";

import { BrowserRouter as Router, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import Category from "./Containers/Category";
import AddProduct from "./Containers/AddProduct";
import AddService from "./Containers/AddService";
import Profil from "./Containers/Profil";
import Reset from "./Containers/resetPassword";
import EditService from "./Containers/EditService";
import PrivacyPolicy from "./Containers/PrivacyPolicy";

function App(props) {
  const [token, setToken] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    }
  }, [props]);
  return (
    <>
      <ReactNotification />
      {token ? (
        <Router>
          <Route exact path="/shop" component={ShopContainer} />
          <Route exact path="/profil" component={Profil} />

          <Route exact path="/avis" component={Avis} />
          <Route exact path="/stats" component={Statiques} />
          <Route exact path="/reservations" component={Reservations} />
          <Route exact path="/addproduct" component={AddProduct} />
          <Route exact path="/service/edit/:id" component={EditService} />

          <Route exact path="/service/:category" component={Service} />
          <Route exact path="/addservice" component={AddService} />

          <Route exact path="/" component={Category} />
        </Router>
      ) : (
        <Router>
          <Route path="/signup" exact component={Signup} />
          <Route path="/reset" exact component={Reset} />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />

          <Route exact path="/" component={Login} />
        </Router>
      )}
    </>
  );
}

export default App;
