import Header from "../Components/header"
import React , {Component} from "react"
import LandingContent from "../Components/signUp/landingContent"
import PartnerForm from "../Components/signUp/partnerForm"
import LandingInfo from "../Components/signUp/landingInfo"
import Footer from "../Components/footer"
import NextForm from "../Components/signUp/nextForm"
import Confirmation from "../Components/signUp/verification"
import LoginForm from "../Components/loginForm"
import { openNotificationWithIcon, postModule } from "../utils/apiService"
import Privacy from "../Components/privacyContent"





class PrivacyPolicy  extends Component {
 

    render(){

        return ( <> 

            <div class="partner-page-container">
      <div class="partner-landing">
        <div class="container"> 
        <Header />
        
        

        </div>
         </div> 
         
         <Privacy />
    
        </div>
        <Footer />
        </>
        )
    }



}



export default PrivacyPolicy


