import React, { Component } from "react";
import Footer from "../Components/footer";
import LoggedHeader from "../Components/loggedHeader";
import Nav from "../Components/nav";
import Partner from "../Components/Profile/Partner";
import { getModule } from "../utils/apiService";

class Profil extends Component {
  render() {
    return (
      <>
        <div className="accueil-container">
          <LoggedHeader />
          <Nav />

          <Partner {...this.state} />
        </div>
        <Footer />
      </>
    );
  }
}

export default Profil;
