import React from "react"


const privacy = props => {

    return ( 
        <div style={{width : "80%" , marginLeft : "10%" }}>
 <p class="p1" style={{textAlign : "center"}}><span class="s1"><strong><em>Privacy Policy:</em></strong></span></p>
<p class="p2"><br/></p>
<p class="p3">Nous Metime, nous nous soucions de la vie priv&eacute;e des utilisateurs de notre site Internet et nous nous engageons &agrave; prot&eacute;ger les informations que les utilisateurs partagent avec nous lorsqu&rsquo;ils utilisent notre Site, et nous nous engageons pleinement &agrave; prot&eacute;ger et &agrave; utiliser vos informations conform&eacute;ment &agrave; la loi applicable.</p>
<p class="p3">Cette Politique de confidentialit&eacute; d&eacute;crit nos pratiques en mati&egrave;re de recueil, d&apos;utilisation et de divulgation de vos informations par le biais de nos propri&eacute;t&eacute;s num&eacute;riques (Formations), lorsque vous acc&eacute;dez aux Services depuis votre appareil.</p>
<p class="p3">Avant d&apos;acc&eacute;der ou d&apos;utiliser l&apos;un de nos Services, veuillez lire attentivement cette Politique de confidentialit&eacute; et vous assurer que vous comprenez parfaitement nos pratiques concernant vos informations. Si vous lisez et comprenez pleinement cette Politique de confidentialit&eacute;, et que vous restez oppos&eacute;(e) &agrave; nos pratiques, vous devez imm&eacute;diatement cesser toute utilisation de nos Propri&eacute;t&eacute;s num&eacute;rique et de nos Services. En utilisant nos Services, vous acceptez les conditions de cette Politique de confidentialit&eacute; et le fait de continuer &agrave; utiliser les Services constitue votre acceptation de cette Politique de confidentialit&eacute; et de tout amendement &agrave; celle-ci.</p>
<p class="p3">Dans la pr&eacute;sente Politique de confidentialit&eacute;, vous trouverez des explications sur les questions suivantes :</p>
<ul class="ul1">
    <li class="li4">Les informations que nous recueillons.</li>
    <li class="li3">Comment nous recueillons les informations ?</li>
    <li class="li3">Pourquoi recueillons- nous les informations ?</li>
    <li class="li3">Avec qui nous partageons les informations ?</li>
    <li class="li3">O&ugrave; sont stock&eacute;es les informations ?</li>
    <li class="li3">Combien de temps sont conserv&eacute;es les informations ?</li>
    <li class="li3">Comment nous prot&eacute;geons les informations.</li>
    <li class="li3">Mineurs</li>
    <li class="li5">Modifications ou mises &agrave; jour de la Politique de confidentialit&eacute;.</li>
</ul>
<p class="p2"><br/></p>
<p class="p3"><span class="s1">1.Les informations que nous recueillons:</span></p>
<p class="p3"><br/></p>
<p class="p3">Vous trouverez ci-dessous les diff&eacute;rents types d&apos;informations que nous pouvons recueillir.</p>
<ul class="ul1">
    <li class="li6">Les informations non identifi&eacute;es et non identifiables, qui peuvent &ecirc;tre fournies par vous lors du processus d&apos;enregistrement ou recueillies lorsque vous utilisez nos Services (les &laquo; Informations non personnelles &raquo;). Les informations non personnelles ne nous permettent pas d&apos;identifier la personne aupr&egrave;s de qui elles ont &eacute;t&eacute; recueillies. Les Informations non personnelles que nous recueillons consistent principalement en des informations techniques et des informations d&apos;utilisation agr&eacute;g&eacute;es.</li>
</ul>
<p class="p7"><br/></p>
<ul class="ul1">
    <li class="li6">Les informations permettant de vous identifier individuellement, c&rsquo;est-&agrave;-dire les informations qui vous identifient ou qui peuvent, moyennant un effort raisonnable, vous identifier (les &laquo; Informations personnelles &raquo;). Les Informations personnelles que nous recueillons par le biais de nos Services sont notamment le nom, l&apos;adresse e-mail, l&apos;adresse, le num&eacute;ro de t&eacute;l&eacute;phone, l&apos;adresse IP ou d&apos;autres informations demand&eacute;es &agrave; chaque fois que n&eacute;cessaire. Si nous combinons des Informations personnelles avec des Informations non personnelles, nous traiterons les informations combin&eacute;es comme des Informations personnelles aussi longtemps qu&apos;elles restent combin&eacute;es.</li>
</ul>
<p class="p2"><br/></p>
<p class="p3"><span class="s1">2.Comment nous recueillons les informations ?</span></p>
<p class="p3"><br/></p>
<p class="p3">Vous trouverez ci-dessous les principales m&eacute;thodes que nous utilisons pour recueillir des informations :</p>
<ul class="ul1">
    <li class="li4">Nous recueillons des informations lorsque vous utilisez les Services. Lorsque vous visitez nos Propri&eacute;t&eacute;s num&eacute;riques ou que vous utilisez nos Services, nous pouvons recueillir, collecter et enregistrer ces utilisations, sessions et informations connexes.</li>
    <li class="li3">Nous recueillons les informations que vous nous fournissez volontairement. Par exemple, nous recueillons les informations que vous nous fournissez lorsque vous nous contactez directement par n&apos;importe quel canal de communication (par exemple lorsque vous nous envoyez un e-mail contenant un commentaire ou un retour d&apos;information).</li>
    <li class="li3">Nous pouvons recueillir des informations aupr&egrave;s de sources tierces, tel que d&eacute;crit ci-dessous.</li>
    <li class="li5">Nous recueillons les informations que vous nous fournissez si vous vous connectez &agrave; nos Services par le biais de services tiers comme Facebook ou Google.</li>
</ul>
<p class="p2"><br/></p>
<p class="p3"><span class="s1">3.Pourquoi nous recueillons les informations ?</span></p>
<p class="p3"><br/></p>
<p class="p3">Nous utilisons vos Informations personnelles aux fins suivantes :</p>
<ul class="ul1">
    <li class="li4">Pour fournir et exploiter les Services.</li>
    <li class="li3">Pour d&eacute;velopper, personnaliser et am&eacute;liorer nos Services.</li>
    <li class="li3">Pour r&eacute;pondre &agrave; vos r&eacute;actions, demandes et requ&ecirc;tes et vous apporter notre assistance.</li>
    <li class="li3">Pour analyser les sch&eacute;mas de demande et d&apos;utilisation.</li>
    <li class="li3">&Agrave; d&apos;autres fins internes, de recherche ou de statistiques.</li>
    <li class="li3">Pour renforcer nos capacit&eacute;s en mati&egrave;re de s&eacute;curit&eacute; des donn&eacute;es et de pr&eacute;vention de la fraude.</li>
    <li class="li3">Pour enqu&ecirc;ter sur les &eacute;ventuelles violations, faire respecter nos conditions ou nos politiques, ou se conformer &agrave; une loi, une r&eacute;glementation ou une autorit&eacute; gouvernementale applicable.</li>
    <li class="li5">Pour vous envoyer des mises &agrave; jour, des avis, des documents promotionnels et d&apos;autres informations concernant nos Services. Si nous vous envoyons des e-mails promotionnels, vous pouvez choisir de ne plus les recevoir en cliquant sur le lien de d&eacute;sabonnement que ces e-mails contiennent.</li>
</ul>
<p class="p3"><br/></p>
<p class="p3"><span class="s1">4.Avec qui nous partageons les informations:</span></p>
<p class="p3"><br/></p>
<p class="p3">Nous pouvons partager vos informations avec nos fournisseurs de services, afin d&apos;exploiter nos Services (par exemple, en stockant des informations sur les services d&apos;h&eacute;bergement tiers, en fournissant notre assistance technique, etc.)</p>
<p class="p3">Nous pouvons &eacute;galement divulguer vos informations dans les circonstances suivantes : (i) pour enqu&ecirc;ter, d&eacute;tecter, pr&eacute;venir ou prendre des mesures concernant des activit&eacute;s ill&eacute;gales ou d&apos;autres actes r&eacute;pr&eacute;hensibles, des soup&ccedil;ons de fraude ou des questions de s&eacute;curit&eacute; ; (ii) pour &eacute;tablir ou exercer nos droits de d&eacute;fense contre des revendications l&eacute;gales ; (iii) pour prot&eacute;ger nos droits, nos biens ou notre s&eacute;curit&eacute; personnelle et ceux de nos utilisateurs ou du grand public ; (iv) si nous ou l&apos;une de nos soci&eacute;t&eacute;s affili&eacute;es subissons un changement de contr&ocirc;le, y compris par le biais d&apos;une fusion, d&apos;une acquisition ou d&apos;un achat de tous ou de la quasi-totalit&eacute; de nos actifs ; (v) pour collecter, d&eacute;tenir et/ou g&eacute;rer vos informations par le biais de nos fournisseurs de services tiers autoris&eacute;s (par exemple, des soci&eacute;t&eacute;s de services en ligne), dans la mesure o&ugrave; cela est raisonnable &agrave; des fins commerciales ; ou (vi) pour coop&eacute;rer avec des tiers dans le but d&apos;am&eacute;liorer votre exp&eacute;rience. Pour &eacute;viter toute confusion possible, nous pouvons transf&eacute;rer et divulguer &agrave; des tiers ou utiliser autrement les Informations non personnelles &agrave; notre seule discr&eacute;tion</p>
<p class="p3">Cookies et technologies similaires</p>
<p class="p3">Lorsque vous visitez ou acc&eacute;dez &agrave; notre Service, nous autorisons certains tiers &agrave; utiliser des balises web, des cookies, des pixels invisibles, des scripts, des balises et d&apos;autres technologies et services d&apos;analyse (les &laquo; Technologies de suivi &raquo;). Ces Technologies de suivi peuvent permettre &agrave; des tiers de recueillir automatiquement des informations vous concernant afin d&apos;am&eacute;liorer la mani&egrave;re dont vous naviguez sur nos Propri&eacute;t&eacute;s num&eacute;riques, d&apos;am&eacute;liorer les performances de nos Propri&eacute;t&eacute;s num&eacute;riques et de personnaliser votre exp&eacute;rience sur nos Propri&eacute;t&eacute;s num&eacute;riques, ainsi qu&apos;&agrave; des fins de s&eacute;curit&eacute; et de pr&eacute;vention de la fraude.</p>
<p class="p3">Pour en savoir plus, consultez notre Politique en mati&egrave;re de cookies.</p>
<p class="p3">Par le biais de notre Service et de nos Propri&eacute;t&eacute;s num&eacute;riques (y compris les sites web et les applications qui d&eacute;ploient notre Service), nous pouvons fournir des publicit&eacute;s qui peuvent &eacute;galement vous &ecirc;tre adapt&eacute;es, par exemple des publicit&eacute;s qui sont bas&eacute;es sur votre comportement de navigation r&eacute;cent sur diff&eacute;rents sites web, navigateurs ou appareils.</p>
<p class="p3">Afin de vous fournir ces publicit&eacute;s, nous pouvons utiliser des cookies et/ou JavaScript et/ou des balises web (y compris des GIF invisibles) et/ou le stockage local HTML5 et/ou d&apos;autres technologies. Nous pouvons &eacute;galement faire appel &agrave; des tiers, comme des annonceurs r&eacute;seau, qui sont des tiers qui affichent des publicit&eacute;s en fonction de vos visites sur les sites web, afin de vous pr&eacute;senter des publicit&eacute;s cibl&eacute;es. Les fournisseurs de r&eacute;seaux publicitaires tiers, les annonceurs, les sponsors et/ou les services de mesure du trafic peuvent &eacute;galement utiliser des cookies et/ou JavaScript et/ou des balises web (y compris des GIF invisibles) et/ou des LSO Flash et/ou d&apos;autres technologies pour mesurer l&apos;efficacit&eacute; de leurs publicit&eacute;s et personnaliser pour vous le contenu publicitaire. Ces cookies et autres technologies tierces sont r&eacute;gis par la politique de confidentialit&eacute; sp&eacute;cifique de chaque tiers, et non pas par la pr&eacute;sente.</p>
<p class="p2"><br/></p>
<p class="p3"><span class="s1">5.O&ugrave; sont stock&eacute;es les informations ?</span></p>
<p class="p3"><br/></p>
<p class="p3">Informations non personnelles:</p>
<p class="p3">Veuillez noter que nos entreprises, ainsi que les partenaires et les fournisseurs de services &agrave; qui nous faisons confiance, sont situ&eacute;s dans le monde entier. Toutes les Informations non personnelles que nous recueillons sont stock&eacute;es et trait&eacute;es dans diverses juridictions &agrave; travers le monde, aux fins d&eacute;taill&eacute;es dans la pr&eacute;sente Politique de confidentialit&eacute;.</p>
<p class="p3">Informations personnelles:</p>
<p class="p3">Les Informations personnelles peuvent &ecirc;tre conserv&eacute;es, trait&eacute;es et stock&eacute;es aux &Eacute;tats-Unis d&apos;Am&eacute;rique, en Irlande, au Japon et dans d&apos;autres juridictions si cela est n&eacute;cessaire pour la bonne prestation de nos services et/ou si la loi l&apos;exige (comme expliqu&eacute; plus en d&eacute;tail ci-dessous).</p>
<p class="p2"><br/></p>
<p class="p3"><span class="s1">6.Combien de temps sont conserv&eacute;es les informations:</span></p>
<p class="p2"><br/></p>
<p class="p3">Veuillez noter que nous conservons les informations que nous recueillons aussi longtemps que n&eacute;cessaire pour fournir les Services et pour nous conformer &agrave; nos obligations l&eacute;gales ou contractuelles envers vous, r&eacute;soudre les litiges et faire respecter nos accords.</p>
<p class="p3">Nous pouvons rectifier, compl&eacute;ter ou supprimer des informations incompl&egrave;tes ou inexactes, &agrave; tout moment et &agrave; notre enti&egrave;re discr&eacute;tion.</p>
<p class="p2"><br/></p>
<p class="p3"><span class="s1">7.Comment prot&eacute;geons nous les informations ?</span></p>
<p class="p3"><br/></p>
<p class="p3">Le service d&apos;h&eacute;bergement de nos Propri&eacute;t&eacute;s num&eacute;riques met &agrave; notre disposition la plateforme en ligne qui nous permet de vous fournir le Service. Vos donn&eacute;es peuvent &ecirc;tre stock&eacute;es par l&apos;interm&eacute;diaire des applications de stockage de donn&eacute;es, de bases de donn&eacute;es ou g&eacute;n&eacute;rales de notre fournisseur d&apos;h&eacute;bergement. Il stocke vos informations sur des serveurs s&eacute;curis&eacute;s derri&egrave;re un pare-feu et offre un acc&egrave;s s&eacute;curis&eacute; HTTPS &agrave; la plupart de ses services</p>
<p class="p2"><br/></p>
<p class="p3"><span class="s1">8.Mineurs:</span></p>
<p class="p2"><br/></p>
<p class="p3">Les Services ne sont pas destin&eacute;s aux utilisateurs mineurs. Nous ne recueillons pas d&eacute;lib&eacute;r&eacute;ment des informations aupr&egrave;s des enfants. Si vous n&apos;&ecirc;tes pas majeur(e), vous ne devez pas t&eacute;l&eacute;charger ou utiliser les Services, ni nous fournir d&apos;informations.</p>
<p class="p2"><br/></p>
<p class="p3">Nous nous r&eacute;servons le droit de demander une preuve d&apos;&acirc;ge &agrave; tout moment afin de veiller &agrave; ce que les mineurs n&apos;utilisent pas nos Services. Si nous apprenons qu&apos;une personne n&apos;ayant pas atteint l&apos;&acirc;ge de la majorit&eacute; utilise nos Services, nous pouvons interdire et bloquer l&apos;acc&egrave;s de cet utilisateur &agrave; nos Services, ainsi que supprimer toutes les informations stock&eacute;es chez nous concernant cet utilisateur. Si vous avez des raisons de croire qu&apos;un mineur a partag&eacute; des informations avec nous, veuillez nous contacter comme indiqu&eacute; ci-dessous.</p>
<p class="p3">Nous n&rsquo;utiliserons vos informations personnelles qu&apos;aux fins d&eacute;finies dans cette Politique de confidentialit&eacute;, si :</p>
<ul class="ul1">
    <li class="li4">L&apos;utilisation de vos Informations personnelles est n&eacute;cessaire pour ex&eacute;cuter un contrat ou prendre des mesures en vue de conclure un contrat avec vous (par exemple, pour vous fournir les Services ou pour vous fournir notre assistance client ou technique)</li>
    <li class="li3">Il nous est n&eacute;cessaire d&apos;utiliser vos Informations personnelles pour nous conformer &agrave; une obligation l&eacute;gale ou r&eacute;glementaire pertinente</li>
    <li class="li5">Il nous est n&eacute;cessaire d&rsquo;utiliser vos Informations personnelles pour nos int&eacute;r&ecirc;ts l&eacute;gitimes en tant qu&apos;entreprise, &agrave; condition que cette utilisation soit &agrave; tout moment proportionn&eacute;e et respectueuse de vos droits &agrave; la vie priv&eacute;e.</li>
</ul>
<p class="p2"><br/></p>
<p class="p3">Si vous r&eacute;sidez dans l&apos;UE, vous pouvez :</p>
<p class="p3"><br/></p>
<ul class="ul1">
    <li class="li4">Demander &agrave; recevoir la confirmation que des Informations personnelles vous concernant sont trait&eacute;es ou non, et acc&eacute;der aux Informations personnelles que nous stockons vous concernant, ainsi qu&apos;&agrave; certaines informations suppl&eacute;mentaires</li>
    <li class="li3">Demander &agrave; recevoir des Informations personnelles que vous nous fournissez directement &agrave; titre volontaire dans un format structur&eacute;, couramment utilis&eacute; et lisible par machine</li>
    <li class="li3">Demander la rectification de vos Informations personnelles qui sont sous notre contr&ocirc;le</li>
    <li class="li3">Demander l&apos;effacement de vos Informations personnelles</li>
    <li class="li3">Vous opposer au traitement des donn&eacute;es personnelles par nos soins</li>
    <li class="li3">Demander la limitation du traitement de vos Informations personnelles par nos soins</li>
    <li class="li5">D&eacute;poser une plainte aupr&egrave;s d&apos;une autorit&eacute; de contr&ocirc;le</li>
</ul>
<p class="p2"><br/></p>
<p class="p3">Toutefois, veuillez noter que ces droits ne sont pas absolus et qu&rsquo;ils peuvent &ecirc;tre soumis &agrave; nos propres int&eacute;r&ecirc;ts l&eacute;gitimes ou exigences r&eacute;glementaires. Si vous avez des questions d&apos;ordre g&eacute;n&eacute;ral sur les Informations personnelles que nous recueillons et sur la mani&egrave;re dont nous les utilisons, veuillez nous contacter de la mani&egrave;re indiqu&eacute;e ci-dessous.</p>
<p class="p3">Dans le cadre de la mise &agrave; disposition des Services &agrave; nos utilisateurs, nous pouvons transf&eacute;rer des informations &agrave; des entit&eacute;s affili&eacute;es ou &agrave; d&apos;autres tiers au-del&agrave; des fronti&egrave;res de votre pays ou juridiction, vers d&apos;autres pays ou juridictions dans le monde. En utilisant les Services, vous consentez au transfert de vos informations en dehors de l&apos;EEE.</p>
<p class="p3">Si vous &ecirc;tes situ&eacute;(e) dans l&apos;EEE, vos Informations personnelles ne pourront &ecirc;tre transf&eacute;r&eacute;es vers des lieux situ&eacute;s en dehors de l&apos;EEE que si nous sommes convaincus qu&rsquo;un niveau de protection ad&eacute;quat ou comparable est en place pour prot&eacute;ger les Informations personnelles. Nous prendrons des mesures raisonnables pour nous assurer que nous avons des accords contractuels appropri&eacute;s avec nos tiers pour garantir que des garanties ad&eacute;quates sont en place de sorte que les risques d&apos;utilisation ill&eacute;gale, de modification, de suppression, de perte ou de vol de vos Informations personnelles soient minimis&eacute;s, et que ces tiers agissent &agrave; tout moment en conformit&eacute; avec les lois applicables.</p>
<p class="p3"><br/></p>
<p class="p3">Droits CCPA relatifs &agrave; vos informations</p>
<p class="p3"><br/></p>
<p class="p3">Si vous &ecirc;tes un r&eacute;sident de Californie utilisant les Services, la loi californienne sur la protection de la vie priv&eacute;e des consommateurs (la &laquo; loi CCPA &raquo;) peut vous donner le droit de demander l&apos;acc&egrave;s &agrave; vos informations et leur suppression.</p>
<p class="p3">Pour exercer votre droit d&apos;acc&egrave;s &agrave; vos informations et &agrave; leur suppression, veuillez nous contacter de la mani&egrave;re indiqu&eacute;e ci-apr&egrave;s.</p>
<p class="p3">Les utilisateurs des Services qui sont r&eacute;sidents de Californie et &acirc;g&eacute;s de moins de 18 ans peuvent demander et obtenir le retrait de tout contenu qu&apos;ils ont publi&eacute; en nous envoyant un e-mail &agrave; l&rsquo;adresse indiqu&eacute;e ci-apr&egrave;s dans la rubrique &laquo; Nous contacter &raquo;.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Toute demande en ce sens devra porter la mention &laquo; California Removal Request &raquo; dans votre e-mail. Toute demande devra fournir une description du contenu que vous souhaitez supprimer et des informations raisonnablement suffisantes pour nous permettre de localiser ce contenu. Nous rejetons toutes les demandes &laquo; California Removal Request &raquo; qui ne sont pas marqu&eacute;es ou envoy&eacute;es correctement, et nous pourrions ne pas &ecirc;tre en mesure de r&eacute;pondre si vous ne fournissez pas les informations ad&eacute;quates. Veuillez noter que votre demande ne garantit pas un retrait complet ou exhaustif du contenu concern&eacute;. Par exemple, les documents que vous avez publi&eacute;s peuvent &ecirc;tre republi&eacute;s par un autre utilisateur ou un tiers.</p>
<p class="p2"><br/></p>
<p class="p3"><span class="s1">9.Modifications ou mises &agrave; jour de la Politique de confidentialit&eacute;:</span></p>
<p class="p3"><br/></p>
<p class="p3">Nous pouvons r&eacute;viser cette politique de confidentialit&eacute; &agrave; chaque fois que n&eacute;cessaire, &agrave; notre seule discr&eacute;tion, et la version la plus r&eacute;cente sera toujours affich&eacute;e sur notre site web (comme indiqu&eacute; dans la rubrique &laquo; Derni&egrave;re r&eacute;vision &raquo;). Nous vous encourageons &agrave; consulter r&eacute;guli&egrave;rement la pr&eacute;sente Politique de confidentialit&eacute; pour prendre connaissance de toute modification. En cas de changements importants, nous publierons sur notre site web un avis pour annoncer ces changements.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Si vous continuez &agrave; utiliser les Services apr&egrave;s la notification de modifications sur notre site web, cela constitue votre reconnaissance de, et votre consentement &agrave; ces modifications de la Politique de confidentialit&eacute; et votre accord &agrave; &ecirc;tre li&eacute;(e) par les conditions de ces modifications.</p>
<p class="p2"><br/></p>
<p class="p2"><br/></p>
<p class="p3">Si vous avez des questions d&apos;ordre g&eacute;n&eacute;ral sur les Services ou les informations que nous recueillons &agrave; votre sujet, ou sur la mani&egrave;re dont nous les utilisons, contactez-nous :</p>
<p class="p2"><br/></p>
<p class="p8"><span class="s2">E-mail:&nbsp;</span><span class="s1">grcc@metime.ma</span><span class="s2">&nbsp;/&nbsp;</span><span class="s1">welcome@metime.ma</span><span class="s2"><span class="Apple-converted-space">&nbsp;</span></span></p>
<p class="p9">Telephone : +212664915899</p>
<p class="p9">Fax: +212520272289</p>
<p class="p2"><br/></p>
<p class="p2"><br/></p>
<p class="p2"><br/></p>
<p class="p2"><br/></p>
        </div>
    )
}

export default privacy