import React, { useState } from "react";

const Order = (props) => {  console.log( props)
 const [startDate , setDate] = useState(new Date(props.startDate))
 const [endDate , setEndDate] = useState(new Date(props.endDate))
 const [show_ , setShow] = useState(true)

  return (
    <div >
      <div class="card-body">
      <div class="avatar">
        <div class="full-name">{props.user.firstName} {props.user.lastName}</div>
      </div>
      <div class="reservation-date">{props.user.phoneNumber}</div>

      <div class="reservation-date">{ (props.startDate)}</div>
      <div class="reservation-time">{(endDate - startDate)/(60 * 1000)} min</div>
      <div class="reservation-price">{props.price}</div>
      <div  onClick={(e) =>{
        e.preventDefault()
        setShow(!show_)}}  placeholder="See More" class="reservation-price" > {show_ ? "Moins De Détails" : "Plus De Détails"}   </div>
      <input checked={props.isCompleted} type="checkbox"  id="flexCheckChecked" />

      <div>

        

      </div>


    </div>
      
      

    {
      props.serviceReservations.map(rsv =>     <div style={{marginLeft : 40 , display :show_ ? "block" : "none" }} className="card-body">
      <div class="reservation-date">{rsv.service.title}</div>

      <div class="reservation-date">{rsv.service.category}</div>
    </div> )
    }
  
    </div>
    
  );
};

export default Order;
