import styled from "styled-components";
import Modal from "./Modal";

export default styled(Modal)`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;

  .hidden {
    display: none;
  }

  .modal-inner {
    position: relative;
    width: 100%;
    overflow: auto;
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5rem;
    cursor: pointer;
  }
`;
