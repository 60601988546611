import React from "react";

const landingContent = (props) => {
  return (
    <div class="partner-desc">
      <div style={{fontSize : 33 , fontWeight : 900}}>
      Boostez Votre Business Avec  <span>MeTime</span>.
      </div>
      <p>
        <span>Avec :</span>
        <img src="images/me time logo-02.png" alt="" />
        <span>votre temps est optimisé à merveille…</span>
      </p>
      <div class="why-meTime">
        <h4>
          Pourquoi travailler en collaboration avec METIME et devenir
          partenaire ?
        </h4>
        <p>
          Prêt à multiplier vos bénéfices ? METIME permet à votre activité
          d’atteindre ses clients potentiels et fidéliser ceux actuels, avec des
          offres attrayantes que vous pouvez publier sur l’application metime;
          vous gagnez en notoriété en étant visible pour tout le monde.
        </p>
      </div>
    </div>
  );
};

export default landingContent;
