import React, { useEffect , useState } from "react";
import { Link } from "react-router-dom";
import { getModule } from "../../utils/apiService";


const PartnerForm = (props) => {

  const [citiles , setCities] = useState([])
  useEffect(() => {
    getModule({module : "cities"}).then(resp => setCities(resp.data))
  } , [])
  return (
    <div class="partner-form">
      <form>
        <div class="title">
          <h4>Inscrivez-vous dés maintenant</h4>
        </div>
        <div >
          <input
            onChange={(e) => props.onChange(e, "lastName")}
            type="text"
            
            placeholder="Nom"
          />
          <input
            onChange={(e) => props.onChange(e, "firstName")}
            type="text"
           
            placeholder="Prénom"
          />
        </div>
        <input
          onChange={(e) => props.onChange(e, "email")}
          type="email"
         
          placeholder="Email"
        />
        <input
          onChange={(e) => props.onChange(e, "password")}
          type="password"
          placeholder="Mot De Passe"
        />
        <select
          type="text"
          class="with-arrow"
          placeholder="Ville"
          defaultValue="0"
          onChange={(e) => props.onChange(e, "cityId")}
        >
                    <option value="">Ville</option>

          {citiles.map(c => <option key={c.id} value={c.id}> {c.label}</option>)}
         
        </select>
        <input
          onChange={(e) => props.onChange(e, "phoneNumber")}
          type="text"
          class="with-arrow"
          placeholder="Numéro De Téléphone"
        />
        <select
          type="text"
          class="with-arrow"
          placeholder="Type de salon "
          onChange={(e) => props.onChange(e, "roomCategory")}
        >
          <option value="">TYPE DE SALON</option>
          <option value="MALE">MALE</option>
          <option value="FEMALE">FEMALE</option>
          <option value="MIXED">MIXED</option>
          <option value="DOMICILE">DOMICILE</option>
        </select>

        <input
          onChange={(e) => props.onChange(e, "seatsCount")}
          type="text"
          class="with-arrow"
          placeholder="Nombre de chaise"
        />

        <button onClick={(e) => props.nextHandler(e, "next", true)}>
          suivant
        </button>
        <br /> <br />
        <Link to="/">
          <span
            style={{ textDecoration: "underline", fontSize: 14, marginTop: 13 }}
          >
            J'ai Deja Un compte
          </span>
        </Link>
      </form>
    </div>
  );
};

export default PartnerForm;
