import React from "react";
import { Link } from "react-router-dom";

const loginForm = (props) => {
  return (
    <div class="partner-form">
      <form>
        <div class="title">
          <h4>Connexion</h4>
        </div>
        <input
          onChange={(e) => props.onChange("email", e.target.value)}
          type="email"
          name=""
          id=""
          placeholder="Email"
        />
        <input
          onChange={(e) => props.onChange("password", e.target.value)}
          type="password"
          placeholder="Mot de passe"
        />
        <button onClick={(e) => props.logHandler(e)}>Connexion</button>
        <br /> <br />
        <Link to="/reset">
          <span style={{ fontWeight: "bold", fontSize: 14, marginTop: 13 }}>
           J'ai Oublie Mon Mot De Passe
          </span>
        </Link>
        <br /> <br />
        <Link to="/signup">
          <span
            style={{ textDecoration: "underline", fontSize: 14, marginTop: 13 }}
          >
            CREER VOTRE COMPTE
          </span>
        </Link>
      </form>
    </div>
  );
};

export default loginForm;
