import React from "react";

const nextForm = (props) => {
  return (
    <div class="partner-form">
      <form>
        <div class="title">
          <h4>Inscrivez-vous dés maintenant</h4>
        </div>
        <input
          onChange={(e) => props.onChange(e, "roomName")}
          type="text"
          name=""
          id=""
          placeholder="Nom du salon"
        />
        <input
          onChange={(e) => props.onChange(e, "roomAddress")}
          type="text"
          placeholder="Adresse"
        />

        <div class="policy">
          <input
            type="checkbox"
            name="policy"
            id="policy"
            class="policy-input"
          />
          <label for="policy">J’accepte la politique de confidentialité</label>
        </div>
        <div>
          <span
            onClick={(e) =>  props.uiChanger(e, "next", false) 
          }
            style={{
              float: "left",
              backgroundColor: "#a6b1e1",
              padding: 10,
              color: "white",
              fontSize: 17
            }}
          >
            {"<"}
          </span>
          <button onClick={(e) => props.nextHandler(e, "verification", true)}>
            envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default nextForm;
