import clsx from "clsx";
import XIcon from "./XIcon";

export default function Modal({
  className,
  children,
  visible,
  onClose,
  containerClassName,
  contentContainerClassName
}) {
  function stopClickPropagation(event) {
    event.stopPropagation();
  }

  function closeModalEventHandler() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <div
      className={clsx(className, containerClassName, {
        hidden: !visible
      })}
      onClick={closeModalEventHandler}
    >
      <XIcon className="close-icon" onClick={closeModalEventHandler} />
      <div
        className={clsx("modal-inner", contentContainerClassName)}
        onClick={stopClickPropagation}
      >
        {children}
      </div>
    </div>
  );
}
