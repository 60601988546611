import React, { Component } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import LoggedHeader from "../Components/loggedHeader";
import Nav from "../Components/nav";
import { Link } from "react-router-dom";
import './category.css'
import { getModule } from "../utils/apiService";

class Category extends Component {
  state = {
    services: []
  };

  componentDidMount() {
    getModule({module:"users/profile"}).then(rsp => 
      {
      
      localStorage.setItem("fullname" , rsp.data.data.rooms[0].name)}
      )
  }
  render() {
    return (
      <>
        <div className="accueil-container">
          <LoggedHeader />
          <Nav />
          <div className="vos-services-container">
            <div className="wrapper">
              <Link to="/service/Coiffure">Coiffure</Link>
              <Link to="/service/Hammam">Hammam &amp; Spa</Link>
              <Link to="/service/Esthetique">Esthetique</Link>
              <Link to="/service/EPILATION_ET_SOINS">EPILATION ET SOINS</Link>
              <Link to="/service/Tatouage"> Tatouage &amp; Piercing</Link>
              <Link to="/service/ONGLERIE">ONGLERIE</Link>

            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Category;
