import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import axios from "axios";
import { BASEAPI, openNotificationWithIcon, postModule } from "../utils/apiService";

const Service = (props) => {
  const [showEdit, setShow] = useState(false);
  const deleteHandler = () => {
    const token = localStorage.getItem("token");
    const query = props.id;

    axios
      .patch(
        BASEAPI + "/services/" + query,
        {
          isArchived: true
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"

            // 'content-type' : 'multipart/form-data'
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          openNotificationWithIcon(
            "success",
            "success",
            "your service has been deleted"
          );
         window.location.reload()
        }
      })
      .catch(
        (error) => console.log(error.message) // error.response.data.message.map(m => openNotificationWithIcon("default" , "error" , m) )
      );
  };

  const dupliquerHandler = () => {
    postModule({module : `services/${props.id}/duplicate`}).then(rsp => {if(rsp.status ===201) {
      openNotificationWithIcon("success" , "Success" , "A New Service Has Been Created Succefully")
      window.location.reload()
    }} )
  }
   return (
    <div class="service">
      <div class="image">
        <img
          src={props.images.length ? BASEAPI + `/services/images/${props.images[0].filename}` : null}
          alt={props.title}
        />

        <div>
          <div class="active"></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    <div className="service" >

      <div class="service-info">
        <h4 class="service-name">{props.title}</h4>
        <h5 class="countdown">{props.duration} min</h5>
      </div>
      <div class="price">
        <h4>Prix</h4>
        <h5>{props.price} dh</h5>
      </div>
      <div class="service-control">
        {props.isVisible ? (
          <i class="fas fa-eye"></i>
        ) : (
          <i class="fas fa-eye-slash"></i>
        )}
        <div
          onClick={() => {
            setShow(!showEdit);
          }}
          class="options"
        >
          <img src="images/Group 12144.png" alt="" />
        </div>
        {showEdit ? (
          <>
            <div class="control-options">
              <Link to={"/service/edit/" + props.id}>
                <img src="images/ic_edit_24px.png" alt="" />
                <span>Modifier</span>
              </Link>
              <div onClick={dupliquerHandler} >
                <img src="images/ic_library_add_24px.png" alt="" />
                <span>Dupliquer</span>
              </div>
              <div onClick={deleteHandler}>
                <img src="images/ic_delete_24px.png" alt="" />
                <span>Supprimer</span>
              </div>
            </div>
          </>
        ) : null}
      </div>
 
      </div>

    </div>
  );
};

export default Service;
