import React, { Component } from "react";
import LoggedHeader from "../Components/loggedHeader";
import Nav from "../Components/nav";
import Footer from "../Components/footer";

import Shop from "../Components/shop";
import { Link } from "react-router-dom";
import { getModule } from "../utils/apiService";

class ShopContainer extends Component {
  state = {
    products: []
  };
  componentDidMount() {
    getModule({ module: "products" }).then((response) =>
      this.setState({ products: response.data.data })
    );
  }

  render() {
    return (
      <>
        <div class="accueil-container">
          <LoggedHeader />
          <Nav />
          <div className="services-container">
            <div class="add-service-btn">
              <Link to="addproduct">
                <button>ajouter un produit</button>
              </Link>
            </div>
          </div>

          <Shop products={this.state.products} />
        </div>
        <Footer />
      </>
    );
  }
}

export default ShopContainer;
