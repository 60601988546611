import React from "react";
import { getModule, openNotificationWithIcon } from "../../utils/apiService";

const EmailVerfication = (props) => {
  const submitToken = (e) => {
    e.preventDefault();
    getModule({
      module: `auth/reset-password/credentials?email=${props.email}&token=${props.token}`
    }).then((response) => {
      if (response.status === 200) {
        props.changeHandler("password_box", true);
      } else {
        openNotificationWithIcon("warning", "error", "The Code is not correct");
      }
    });
  };

  return (
    <form>
      <div class="title">
        <h4>Rest Password</h4>
      </div>
      <input
        onChange={(e) => props.changeHandler("token", e.target.value)}
        type="text"
        placeholder="Set The Code Please"
      />
      <button onClick={(e) => submitToken(e)}>Reset</button>
      <br /> <br />
    </form>
  );
};

export default EmailVerfication;
