import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASEAPI, getModule, openNotificationWithIcon } from "../../utils/apiService";
import Room from "./room";

const Partner = (props) => {
  const [data, setData] = useState({});
  const [room, setRoom] = useState([]);
  const [passwords, setPasswords] = useState({});
  const [updatedDate, setUpdatedData] = useState({});


  useEffect(() => {
    getModule({ module: "users/profile" }).then((resp) => {
      setData({ ...resp.data.data });
      setRoom(resp.data.data.rooms);
    });

  }, []); 
  const ChangePasswordsHandler = (e) => {
    e.preventDefault();
    if (passwords.new !== passwords.confirm) {
      openNotificationWithIcon(
        "warning",
        "Confirmation Error",
        "Please Check Your Inputs"
      );
    } else {
      const token = localStorage.getItem("token");

      axios
        .patch( BASEAPI +
          "users/profile/",
          { oldPassword: passwords.old, password: passwords.new },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }
          }
        )
        .then((rsp) => {
          if (rsp.status === 200) {
            openNotificationWithIcon(
              "success",
              "success",
              "your password has been changed"
            );
          }
        });
    }
  };

  const saveModifs = (e) => {
    e.preventDefault();

    let updatedData_ = { ...updatedDate };
    

    const token = localStorage.getItem("token");

    axios
      .patch( BASEAPI + 
        "users/profile/",
        { ...updatedData_ },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        }
      )
      .then((rsp) => {
        if (rsp.status === 200) {
          openNotificationWithIcon(
            "success",
            "success",
            "your profile has been changed"
          );
        }
      });
  };

  const ChangeHandler = (e, key) => {
    let updatedData_ = updatedDate;
    updatedData_[key] = e.target.value;
    setUpdatedData({ ...updatedData_ });
  };
  //select profile img to upload
  
  return (
    <div className="edit-profile-container">
      <div className="edit-img">
        <img src="images/image1-1.png" alt="" />
      </div>
      <div className="edit-profile-details">
        <div className="title">
          <h3>DÉTAILS PERSONNELS</h3>

          <button
            onClick={(e) => saveModifs(e)}
            className="edit-details detailsSave profile-btn"
            style={{ display: "block" }}
          >
            Enregistrer
          </button>
        </div>
        <div className="details-content">
          <div>
            <div className="form-group">
              <label>Nom : </label>
              <input
                onChange={(e) => ChangeHandler(e, "lastName")}
                type="text"
                placeholder={data.lastName}
              />
            </div>
            <div className="form-group">
              <label>prénom : </label>
              <input
                type="text"
                onChange={(e) => ChangeHandler(e, "firstName")}
                placeholder={data.firstName}
              />
            </div>
            <div className="form-group">
              <label>Email : </label>
              <input
                type="email"
                onChange={(e) => ChangeHandler(e, "email")}
                placeholder={data.email}
                style={{ pointerEvents: "all" }}
              />
            </div>
            <h4>Mot De Passe : </h4>
            <div className="form-group password">
              <input
                onChange={(e) =>
                  setPasswords({ ...passwords, old: e.target.value })
                }
                type="password"
                placeholder="Ancien Mot De Passe"
              />
              <input
                onChange={(e) =>
                  setPasswords({ ...passwords, new: e.target.value })
                }
                type="password"
                placeholder="Nouveau Mot De Passe"
              />

              <input
                onChange={(e) =>
                  setPasswords({ ...passwords, confirm: e.target.value })
                }
                type="password"
                placeholder="Confirmer Nouveau Mot De Passe"
              />
              <button
                onClick={ChangePasswordsHandler}
                className="edit-details detailsSave "
              >
                Changer Mot De Passe
              </button>
            </div>
          </div>
        </div>
      </div>
      {room.map((rm) => {
        delete rm.image;
        return <Room key={rm.id} {...rm} />;
      })}
    </div>
  );
};

export default Partner;
