import React, { useState } from "react";

const EmailForm = (props) => {
  return (
    <form>
      <div class="title">
        <h4>Rest Password</h4>
      </div>
      <input
        onChange={(e) => props.changeHandler("email", e.target.value)}
        type="email"
        name=""
        id=""
        placeholder="Email"
      />
      <button onClick={(e) => props.logHandler(e)}>Reset</button>
      <br /> <br />
    </form>
  );
};

export default EmailForm;
