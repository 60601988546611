import React from "react";
import './footer.css'

const footer = (props) => {

  const displayContent = (e) => {
    // display-content
    if (e.target.tagName === 'SPAN') {
      e.target.parentElement.nextElementSibling.classList.toggle('display-content')
    }
  }
  return (
    <footer>
      <div className="footer-container">
        <div className="social-top">
          <img src="images/Group 12461.png" alt="" />
          <div>
            <a href="https://www.instagram.com/metime.ma/" target="_blank">
              <img src="./instagram.svg" alt="instagram" />
            </a>
            <a href="https://twitter.com/MeTimeMorocco" target="_blank">
              <img src="./twitter.svg" alt="twitter" />
            </a>
            <a href="https://www.facebook.com/metime.timerc" target="_blank">
              <img src="./facebook.svg" alt="facebook" />
            </a>
          </div>
        </div>
        <div className="about">
        <h5 onClick={(e)=> displayContent(e)}>
            <span>A propos</span>
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path d="M6.102 8c-1.074 0-1.648 1.265-.94 2.073l5.521 6.31a1.75 1.75 0 0 0 2.634 0l5.522-6.31c.707-.808.133-2.073-.94-2.073H6.101Z" fill="#fff" /> </svg>
          </h5>
          <div>
            <p>
            Avec ses services et ses produits, Metime change le business de la beauté marocaine. Notre entreprise est axée sur les consommateurs et l'amour de soi, et nous voulons inspirer les individus à exprimer leur amour pour eux-mêmes en se connectant avec leur corps. Chacun peut s'exprimer à travers la beauté, prendre soin de soi et poursuivre sa passion avec Metime.

</p>
          </div>
        </div>
        <div className="contact">
          <h5 onClick={(e) => displayContent(e)}>
            <span>Contact</span>
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path d="M6.102 8c-1.074 0-1.648 1.265-.94 2.073l5.521 6.31a1.75 1.75 0 0 0 2.634 0l5.522-6.31c.707-.808.133-2.073-.94-2.073H6.101Z" fill="#fff" /> </svg>
          </h5>
          <div>
            <a href="mailto:Welcome@metime.ma" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              Welcome@metime.ma
            </a>
            <a href="tel:+2120520272289" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              0520272289
            </a>
            
          </div>
        </div>
        <div className="social-media">
          <h5 onClick={(e) => displayContent(e)}>
            <span>Réseaux Sociaux</span>
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path d="M6.102 8c-1.074 0-1.648 1.265-.94 2.073l5.521 6.31a1.75 1.75 0 0 0 2.634 0l5.522-6.31c.707-.808.133-2.073-.94-2.073H6.101Z" fill="#fff" /> </svg>
          </h5>
          <div>
            <a href="https://www.instagram.com/metime.ma/" target="_blank">
              <img src="./instagram.svg" alt="instagram" />
            </a>
            <a href="https://twitter.com/MeTimeMorocco" target="_blank">
              <img src="./twitter.svg" alt="twitter" />
            </a>
            <a href="https://www.facebook.com/metime.timerc" target="_blank">
              <img src="./facebook.svg" alt="facebook" />
            </a>
          </div>
        </div>
        <div className="app">
          <h5 onClick={(e) => displayContent(e)}>
            <span>Téléchargez l’application</span>
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path d="M6.102 8c-1.074 0-1.648 1.265-.94 2.073l5.521 6.31a1.75 1.75 0 0 0 2.634 0l5.522-6.31c.707-.808.133-2.073-.94-2.073H6.101Z" fill="#fff" /> </svg>
          </h5>
          <div>
            <a href="" target="_blank">
              <img src="images/Group 3.png" alt="download app" />
            </a>
            <a href="" target="_blank">
              <img src="images/Group 12.png" alt="download app" />
            </a>
          </div>
        </div>
        <h4 className="copyright">
          Metime©<span>{new Date().getFullYear()}</span>
        </h4>
      </div>
      <hr />
    </footer>
  );
};

export default footer;
