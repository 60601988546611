import React, { Component } from "react";
import Footer from "../Components/footer";
import LoggedHeader from "../Components/loggedHeader";
import Nav from "../Components/nav";
import { openNotificationWithIcon, postModule } from "../utils/apiService";

import axios from "axios";

class AddProduct extends Component {
  state = {};

  submitHandler(e) {
    e.preventDefault();
    const formdata = new FormData();
    Object.keys(this.state).map((key) => {
      formdata.append(key, this.state[key]);
    });
    const token = localStorage.getItem("token");
    postModule({module : "products" , data : formdata})
      .then((response) => {
        if (response.status === 201) {
          openNotificationWithIcon(
            "success",
            "success",
            "your product has been created"
          );
          window.location.reload();
        }
      })
      .catch((error) =>
        error.response.data.message.map((m) =>
          openNotificationWithIcon("default", "error", m)
        )
      );
  }

  render() {
    return (
      <>
        <div class="accueil-container">
          <LoggedHeader />
          <Nav />
          <div class="vos-shop-container">
            <form>
              <div class="vos-ship-content">
                <div>
                 { /* <div class="category">
                    <label for="">Catégorie</label>
                    <select name="" id="">
                      <option
                        value="Sélectionner Catégorie"
                        selected=""
                        disabled=""
                      >
                        Sélectionner Catégorie
                      </option>
                      <option value="Catégorie">Catégorie</option>
                    </select>
    </div> */}
                  <div class="vos-shop-img">
                    <div class="upload-img">
                      <div>
                        <input
                          onChange={(e) =>
                            this.setState({ image: e.target.files[0] })
                          }
                          type="file"
                          accept=""
                        />
                      </div>
                      <img src="images/photo.png" alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="product-title inputs-group">
                    <div>
                      <label for="">Sous-Titre Du Produit</label>
                      <input
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                        type="text"
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                  <div class="desc inputs-group">
                    <label for="">Description Du Produit</label>
                    <textarea
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  <div class="price-size inputs-group">
                    <div>
                      <label for="">Prix</label>
                      <input
                        onChange={(e) =>
                          this.setState({ price: e.target.value })
                        }
                        type="text"
                        name=""
                        id=""
                      />
                    </div>
                    <div>
                      <label for="">Disponibilité</label>
                      <input
                        onChange={(e) =>
                          this.setState({ quantity: e.target.value })
                        }
                        type="text"
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                  <div class="availablity inputs-group">
                    <div>
                      <button
                        onClick={(e) => this.submitHandler(e)}
                        class="save"
                      >
                        enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default AddProduct;
