import React from "react";
import DescAvis from "./descAvis";
import FullAvis from "./fullAvis";
import StarAvis from "./starAvis";

const avis = (props) => {
  return (
    <div class="opinion-container">
      <div class="opinion">
        <div class="opinion-content">
          <div class="card">
           {/* <FullAvis />
            <StarAvis />
            <StarAvis />
            <StarAvis />
            <StarAvis />
           
            <DescAvis />*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default avis;
