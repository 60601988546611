import React from "react";
import { BASE } from "../../utils/apiService";
import './shop.css'
const shop = (props) => {
  return (
    <div className="product-item">
      <img src={BASE + "/products/images/" + props.filename} alt="product" />
      <h3>{props.title}</h3>
      <p>{props.description}</p>
      <span> {props.price}DHs</span>
    </div>
  );
};

export default shop;
