import React, { Component } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import LoggedHeader from "../Components/loggedHeader";
import Nav from "../Components/nav";
import Service from "../Components/service";
import { getModule } from "../utils/apiService";
import { Link } from "react-router-dom";
import './services.css'
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
const MyPaginate = styled(ReactPaginate).attrs({
  // You can redifine classes here, if you want.
  activeClassName: 'active', // default to "disabled"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
class Services extends Component {
  state = {
    services: [] ,
    activePage: 1 , 
    count : 700

  };
  getServices() {
    const query = this.props.match.params.category;
    console.log("im here")

    getModule({ module: `services?category=${query.toUpperCase()}&page=${this.state.activePage}` }).then(
      (rsp) => {
        this.setState({ services: rsp.data.data , count : rsp.data.count
        
        });
      }
    );
  }
  componentDidMount() {
    this.getServices()
    
  }
  async handlePageChange(pageNumber) {
    console.log(pageNumber.selected + 1)
   await this.setState({activePage: pageNumber.selected + 1});
    this.getServices()
  }
 
  render() {
    return (
      <>
        <div className="accueil-container">
          <LoggedHeader />
          <Nav />
          <div className="services-container">
            <div className="add-service-btn">
              <Link to="/addservice">
                <button>ajouter un service</button>
              </Link>
            </div>
            <div className="services">
              <div className="title-bar">
                <div className="squares">
                  <div>
                    <img src="images/Group 12145.png" alt="" />
                  </div>
                  <div>
                    <img src="images/Group 12145.png" alt="" />
                  </div>
                </div>
                <div className="title">{this.props.match.params.category}</div>
              </div>

              <div className="service-content">
                {this.state.services.map((s) => (
                  <Service key={s.id} {...s} />
                ))}
              </div>
              
            </div>
            <div>
           
            
        
        <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={this.handlePageChange.bind(this)}
        pageRangeDisplayed={3}
        pageCount={ Math.ceil(this.state.count/20) }
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        activeClassName="active"
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        onClick={(clickEvent) => {
          this.handlePageChange( clickEvent.nextSelectedPage )
          // Return false to prevent standard page change,
          // return false; // --> Will do nothing.
          // return a number to choose the next page,
          // return 4; --> Will go to page 5 (index 4)
          // return nothing (undefined) to let standard behavior take place.
        }}
      />
              </div>

          </div>
          {/*   <div className="partner-landing-content">
            <LandingContent />
        { this.state.verification ? <Confirmation /> : this.state.next ? <NextForm nextHandler = {this.nextHandler.bind(this)} />  :   <PartnerForm nextHandler = {this.nextHandler.bind(this)} />}

        </div>*/}
        
       
        </div>
       
        <Footer />
      </>
    );
  }
}

export default Services;
