import Header from "../Components/header";
import React, { Component } from "react";
import LandingContent from "../Components/signUp/landingContent";
import PartnerForm from "../Components/signUp/partnerForm";
import LandingInfo from "../Components/signUp/landingInfo";
import Footer from "../Components/footer";
import NextForm from "../Components/signUp/nextForm";
import Confirmation from "../Components/signUp/verification";
import { openNotificationWithIcon, postModule } from "../utils/apiService";

class Signup extends Component {
  state = {
    dataForm: {},
    next: false,
    verification: false
  };
  changeHandler(e, key) {
    const updatedData = this.state;
    updatedData.dataForm[key] = e.target.value;
    this.setState(updatedData);
  }
  nextHandler(e, key, value) {
    e.preventDefault();

    const updatedData = this.state;
    updatedData[key] = value;
    this.setState(updatedData);
  }
  submitHandler(e) {
    e.preventDefault();
    let updatedData = this.state;
    updatedData.dataForm.roomPhoneNumber = updatedData.dataForm.phoneNumber;
    updatedData.dataForm.seatsCount = parseInt(updatedData.dataForm.seatsCount);
    updatedData.dataForm.cityId = parseInt(updatedData.dataForm.cityId);
    updatedData.dataForm["roomCityId"] = updatedData.dataForm.cityId;

    this.setState(updatedData);

    postModule({ module: "auth/register", data: this.state.dataForm }).then(
      (rsp) => {
        if (rsp.successful) {
          openNotificationWithIcon(
            "success",
            "Your Account has been created",
            "Please Verify Your Email"
          );
          this.setState({ verification: true });
        } else {
          if (rsp.response.statusCode === 422) {
            rsp.response.message.map((r) =>
              openNotificationWithIcon("warning", "Caution", r)
            );
          } else {
            rsp.response.details.map((r) =>
              openNotificationWithIcon("warning", r.key, r.message)
            );
          }

        }
      }
    );
  }
  render() {
    return (
      <>
        <div class="partner-page-container">
          <div class="partner-landing">
            <div class="container">
              <Header />
              <div className="partner-landing-content">
                <LandingContent />
                {this.state.verification ? (
                  <Confirmation email={this.state.dataForm.email} />
                ) : this.state.next ? (
                  <NextForm
                  uiChanger = {this.nextHandler.bind(this)}
                    onChange={this.changeHandler.bind(this)}
                    nextHandler={this.submitHandler.bind(this)}
                  />
                ) : (
                  <PartnerForm
                    onChange={this.changeHandler.bind(this)}
                    nextHandler={this.nextHandler.bind(this)}
                  />
                )}
              </div>
            </div>
          </div>
          <LandingInfo />
        </div>
        <Footer />
      </>
    );
  }
}

export default Signup;
