import React, { useState } from "react";
import Order from "./order";

const Orders = (props) => {
  return (
    <div class="card">
     
    
        {props.reservations
          .map((r, index) => (
            <Order {...r} key={index} />
          ))}
    </div>
  );
};

export default Orders;
