import React, { Component } from "react";
import Footer from "../Components/footer";
import LoggedHeader from "../Components/loggedHeader";
import Nav from "../Components/nav";
import Chart from "chart.js/auto";

class Statiques extends Component {
  componentDidMount() {
    var income = document.getElementById("income").getContext("2d");
    var Croissance = {
      data: [, 0, 30, 20, 34, 40, 58, 42, 88],
      lineTension: 0,
      fill: false,
      borderColor: "#A6B1E1"
    };

    var MoisPrécédent = {
      data: [, 25, 20, 41, 45, 23, 32, 24],
      lineTension: 0,
      fill: false,
      borderColor: "#101539"
    };
    var CeMois = {
      data: [32, 28, 35, 10, 20, 41, 35],
      lineTension: 0,
      fill: false,
      borderColor: "#10153980"
    };

    var date = {
      labels: [
        "",
        "12sep",
        "13sep",
        "14sep",
        "15sep",
        "16sep",
        "17sep",
        "18sep",
        "19sep"
      ],
      datasets: [Croissance, MoisPrécédent, CeMois]
    };

    var lineChart = new Chart(income, {
      type: "line",
      data: date,
      options: {
        title: {
          display: false
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
    // end income -------------------------------------------
    // start account-retention ------------------------------------------
    new Chart(document.getElementById("doughnut-account"), {
      type: "doughnut",
      data: {
        datasets: [
          {
            backgroundColor: ["#A6B1E1", "#F4EEFF"],
            data: [30, 70]
          }
        ]
      },
      options: {
        title: {
          display: false,
          text: "Predicted world population (millions) in 2050"
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });

    new Chart(document.getElementById("bar-chart-grouped-account"), {
      type: "bar",
      data: {
        labels: ["sep", "sep", "sep", "sep", "sep", "sep"],

        datasets: [
          {
            backgroundColor: "#A6B1E1",
            data: [1500, 1800, 1500, 2000, 2000, 1500]
          }
        ]
      },
      options: {
        title: {
          display: false
        },
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            ticks: {
              display: false
            }
          }
        }
      }
    });
  }
  render() {
    var Croissance = {
      data: [, 0, 30, 20, 34, 40, 58, 42, 88],
      lineTension: 0,
      fill: false,
      borderColor: "#A6B1E1"
    };

    var MoisPrécédent = {
      data: [, 25, 20, 41, 45, 23, 32, 24],
      lineTension: 0,
      fill: false,
      borderColor: "#101539"
    };
    var CeMois = {
      data: [32, 28, 35, 10, 20, 41, 35],
      lineTension: 0,
      fill: false,
      borderColor: "#10153980"
    };

    var date = {
      labels: [
        "",
        "12sep",
        "13sep",
        "14sep",
        "15sep",
        "16sep",
        "17sep",
        "18sep",
        "19sep"
      ],
      datasets: [Croissance, MoisPrécédent, CeMois]
    };
    return (
      <>
        <div class="accueil-container">
          <LoggedHeader />
          <Nav />
          <div class="statistics-container">
            <div class="statistics-content">
              <div class="statistics-chart">
                <div class="income">
                  <h5>Croissance Des Revenus</h5>
                  <div>
                    <div class="chart-income">
                      <div class="average-rate">
                        <span>38,4K</span>
                        <span>Taux Moyen</span>
                      </div>
                      <div class="chart-income-help">
                        <ul>
                          <li>
                            <span></span>Croissance
                          </li>
                          <li>
                            <span></span>Mois Précédent
                          </li>
                          <li>
                            <span></span>Ce Mois
                          </li>
                        </ul>
                      </div>
                    </div>
                    <canvas id="income" width="100%" height="50px"></canvas>
                  </div>
                </div>
                <div class="account-retention">
                  <h5>Account Retention</h5>
                  <div class="chart-account">
                    <div class="doughnut">
                      <div class="total-of-expenses">
                        <span>MAD 2,548</span>
                        <span>Total Des Depenses</span>
                      </div>
                      <div>
                        <canvas id="doughnut-account"></canvas>
                        <div>
                          <span class="homme">Homme</span>
                          <span class="femme">Femme</span>
                        </div>
                      </div>
                    </div>
                    <div class="bar">
                      <div class="sales">
                        <span>MAD 2,548</span>
                        <span>Ventes</span>
                      </div>
                      <canvas
                        id="bar-chart-grouped-account"
                        width="70%"
                        height="20"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="statistics-reservation">
                <h5>Liste De Réservation</h5>
                <span>Voir L’histoire</span>
              </div>
              <div class="reservation-list">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">Billing Name</th>
                      <th scope="col">Date</th>
                      <th scope="col">Total</th>
                      <th scope="col">Status</th>
                      <th scope="col">Détails</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status species">
                        <span>espèce</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="id">#AB3054</td>
                      <td class="belling">
                        <img src="images/image1-1.png" alt="" />
                        <span>Rim Charfi</span>
                      </td>
                      <td class="date">07 Oct, 2020</td>
                      <td class="total">400Dh</td>
                      <td class="status">
                        <span>En ligne</span>
                      </td>
                      <td class="details">
                        <span>détails</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Statiques;
