import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { BASEAPI, getModule, openNotificationWithIcon } from "../../utils/apiService";
import Modal from "../Modal";
//working hours
import OpenCloseSelect from "./OpenCloseSelect";
import './profile.css'

const Room = (props) => {
  const [roomData, setRoom] = useState({});
  const [daysHours , setDays] =useState({})
  const [cities , setCities] = useState([])
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
  });
  const [currentPostion, setCurrentPostion] = useState({
    lat: 33.5722678,
    lng: -7.6570319
  });
  const [showMapModal, setShowMapModal] = useState(false);

  const changeHandler = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    let updatedData = { ...roomData };
    delete updatedData["id"];
    delete updatedData["createdAt"];
    delete updatedData["updatedAt"];
    delete updatedData["userId"];
    delete updatedData["workingHours"];
    delete updatedData["latitude"];
    delete updatedData["longitude"];

    const formData = new FormData();

    Object.entries(updatedData).forEach(function appendField(fieldEntry) {
      const [fieldName, value] = fieldEntry;
      if (fieldName == "image" && !value) {
        return;
      }

      formData.append(fieldName, value);
    });

    axios
      .patch(
        BASEAPI + "/rooms/" + roomData.id,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        }
      )
      .then((rsp) => {
        if (rsp.status === 200) {
          openNotificationWithIcon(
            "success",
            "success",
            "Your Info Has been Changed Succefully"
          );
          window.location.reload();
        }
      });
  };

  //send open and close time for one day

  //hide error dialog
  const hideDialog = () => {
    document.querySelector('.working-hours .dialog').classList.toggle('display-dialog')
  }
  const minutesConfig =(m) => {
    return m === 0 ? "00" : m
  }
  const hoursConfig =(m) => {
    return m > 9 ? m : `0${m}`
  }

  useEffect(() => {
    setRoom({ ...props });
    if (props.latitude && props.longitude) {
      setCurrentPostion({
        lat: props.latitude,
        lng: props.longitude
      });
    }
    const workingHours = {}
    props.workingHours.map(r => {
      const day_open= new Date(r.startTime)
      const day_close= new Date(r.endTime)

      
      workingHours[r.dayOfWeek] = {
      open : hoursConfig( day_open.getHours()) +":" + minutesConfig(day_open.getMinutes()) ,
      close : hoursConfig (day_close.getHours()) +":" + minutesConfig(day_close.getMinutes())

    }})
    setDays(workingHours)
    getModule({module : "cities"}).then(resp => setCities(resp.data))

  }, []);
  const changeTextHandler = (e, key) => {
    let value = e.target.value
    if(key ==="cityId") {
      value = parseInt(value)
    }
    setRoom({ ...roomData, [key]: value });
  };

  function changeFileHandler(e, key) {
    const [value] = e.target.files;

    setRoom({ ...roomData, [key]: value });
  }

 

  function updateLocationHandler() {
    const token = localStorage.getItem("token");
    const formData = new FormData();


    formData.append("latitude", currentPostion.lat);
    formData.append("longitude", currentPostion.lng);
    console.log(currentPostion.lat , currentPostion.lng )
    axios
      .patch(
        BASEAPI + "/rooms/" + roomData.id,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        }
      )
      .then((rsp) => {
        if (rsp.status === 200) {
          setRoom({
            ...roomData,
            latitude: currentPostion.lat,
            longitude: currentPostion.lng
          });
          openNotificationWithIcon(
            "success",
            "success",
            "Your Info Has been Changed Succefully"
          );
        }
      });
  }

 

  const DAYS = ["MON" , "TUE" , "WED" , "THU" , "FRI" , "SAT" , "SUN"]  
  const DAYS_LABEL = {MON :"Lundi " ,TUE: "Mardi" ,WED: "Mercredi" ,THU: "Jeudi " ,FRI: "Vendredi" ,SAT: "Samedi " ,SUN: "Dimanche "}

  return (
    <>
      <div className="edit-salon">
        <div className="title">
          <h3>Salon</h3>
        </div>
        <div className="salon-content">
          <div>
            <div className="form-group">
              <label>Image :</label>
              <label className="profile-btn">
                Parcourir l'image
                <input
                  style={{
                    display: "none"
                  }}
                  onChange={(e) => changeFileHandler(e, "image")}
                  type="file"
                />
              </label>
            </div>

            <div>
              <label>Location :</label>
              <button className="profile-btn" onClick={() => updateLocationHandler()}>Update location</button>
            </div>

            <div className="form-group">
              <label>Nom Du Salon :</label>
              <input
                onChange={(e) => changeTextHandler(e, "name")}
                type="text"
                placeholder={roomData.name}
              />
            </div>
            <div className="form-group">
              <label>Adresse :</label>
              <input
                onChange={(e) => changeTextHandler(e, "address")}
                type="text"
                placeholder={roomData.address}
              />
            </div>
            <div className="form-group">
              <label>Latitude :</label>
              <input
                onChange={(e) => setCurrentPostion({
                  ...currentPostion , 
                  lat : e.target.value
                })}
                type="text"
                placeholder={currentPostion.lat}
              />
            </div>
            <div className="form-group">
              <label>Longitude :</label>
              <input
                onChange={(e) => setCurrentPostion({
                  ...currentPostion , 
                  lng : e.target.value
                })}
                type="text"
                placeholder={currentPostion.lng}
              />
            </div>
            <div title="working hours" className="working-hours" >
              <h4>Horaire de d’ouverture et de fermeture 
</h4>
              <div className="wrapper">
                {DAYS.map((d , i)=> <div key={d}> <label data-day="MON">{DAYS_LABEL[d]}</label>
                <OpenCloseSelect  close ={ daysHours[d] ? daysHours[d].close : ""}  open={ daysHours[d] ? daysHours[d].open : ""} day = {d} id ={roomData.id}
                  /> </div>)}
              </div>
            <div className="dialog" onClick={() => hideDialog()}>
                  <p>sorry! select valid opening and closing time</p>
                </div>
            </div>
          </div>
          <div>
            <div className="form-group">
              <label>Nombre De Chaise : </label>
              <input
                onChange={(e) => changeTextHandler(e, "seatsCount")}
                type="text"
                placeholder={roomData.seatsCount}
              />
            </div>
            <div className="form-group">
              <label>Ville : </label>
              <select value={roomData.cityId} onChange={(e) => changeTextHandler(e , "cityId")}>
                <option value="Choisir Ville" >Choisir Ville</option>
                {cities.map(c => <option key={c.id} value={c.id}> {c.label}</option>)}

              </select>
            </div>
            <div className="form-group">
              <label>Numéro de téléphone : </label>
              <input
                onChange={(e) => changeTextHandler(e, "phoneNumber")}
                type="text"
                placeholder={roomData.phoneNumber}
              />
            </div>
            <div className="form-group">
              <label>Catégorie De Salon : </label>
              <select
                className="with-arrow"
                placeholder="Type de salon "
                value={roomData.roomCategory}
                onChange={(e) => changeTextHandler(e, "roomCategory")}
              >
                <option value="">TYPE DE SALON</option>
                <option value="MALE">Homme </option>
                <option value="FEMALE">Femme </option>
                <option value="MIXED">Mix </option>
                <option value="DOMICILE">DOMICILE</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="add-salon-btn ">
        {/* <button className="profile-btn" onClick={workingHoursHandler}>Working hours</button> */}
        <button className="profile-btn" onClick={changeHandler}>Enregistrer</button>
      </div>

    </>
  );
};

export default Room;
