import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/footer";
import LoggedHeader from "../Components/loggedHeader";
import Nav from "../Components/nav";
import {
  getModule,
  openNotificationWithIcon,
  postModule,
  BASE
} from "../utils/apiService";

class AddService extends Component {
  state = {
    isVisible: true,
    isArchived: false
  };

  submitHandler(e) {
    e.preventDefault();
    const formdata = new FormData();
    Object.keys(this.state).map((key) => {
      formdata.append(key, this.state[key]);
    });
    postModule({module : "services" , data : formdata})
      .then((response) => {
        if (response.status === 201) {
          openNotificationWithIcon(
            "success",
            "success",
            "your service has been created"
          );
           window.location.reload()
        }
        if(!response.successful) {
          console.log(response.response.message.length )
          if(Array.isArray(response.response.message)){
          response.response.message.map((m) =>
          openNotificationWithIcon("default", "error", m)
        )}
        else{ 
        openNotificationWithIcon("default", "error", response.response.message)
      }
        }

      
      }
      )
      
  }

  // multiple imgs

  handleFileInput(e) {
    const fileInputs = document.querySelectorAll('input[type=file]')
    fileInputs[e.target.dataset.num].click()
  }

  handleInputChange(e) {
    const spans = document.querySelectorAll('.add-service-img span')
    spans[e.target.dataset.num].style.backgroundImage = `url("${URL.createObjectURL(e.target.files[0])}")`
    spans[e.target.dataset.num].textContent = ''
    this.setState({ image: e.target.files[0] });
  }
  render() {

    return (
      <>
        <div class="accueil-container">
          <LoggedHeader />
          <Nav />
          <div class="services-container">
            <div class="add-service-form">
              <form>
                <div class="cancel-post-btn">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.goBack();
                    }}
                    class="cancel"
                  >
                    annuler
                  </button>

                  <button onClick={(e) => this.submitHandler(e)} class="save">
                    enregistrer
                  </button>
                </div>
                <div class="general-info">
                  <div class="general-info-title">Informations Générales</div>
                  <div class="info">
                    <div class="form-info">
                      <div class="service-title">
                        <label >
                          Titre Du Service <span>30</span>
                        </label>
                        <input
                          onChange={(e) =>
                            this.setState({ title: e.target.value })
                          }
                          type="text"
                        />
                      </div>
                      <div class="untitled-service">
                        <label >
                          Description du service (Dites un peu plus sur votre service) <span>70</span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>

                    </div>

                    <div className="upload-img add-service-img">
                      <input type="file"  accept="image/*" onChange={e => this.handleInputChange(e)} data-num={0} />
                      <span onClick={e => this.handleFileInput(e)} data-num={0}>Pic</span>

                    </div>
                    {/* <div class="upload-img">
                      <div>
                        <input
                          onChange={(e) => {
                            this.setState({ image: e.target.files[0] });
                          }}
                          type="file"
                          accept=""
                        />
                      </div>
                      <img src="images/photo.png" alt="" />
                    </div> */}
                  </div>
                  <div class="desc">
                    <div>Catégorie De Service</div>
                    <br />
                    <select
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          category: e.target.value
                        })
                      }
                    >
                      <option>Catégorie</option>
                      <option value="COIFFURE">COIFFURE</option>
                      <option value="TATOUAGE">TATOUAGE</option>
                      <option value="HAMMAM">HAMMAM</option>
                      <option value="EPILATION_ET_SOINS">EPILATION ET SOINS</option>

                      <option value="ONGLERIE">ONGLERIE</option>
                    </select>
                  </div>

                  <div class="desc">
                    <div>Description Et Medias (Dites un peu plus sur votre service)</div>
                    <p>
                      Ajoutez Plus De Texte Et D’images Pour Mettre En Valeur
                      Votre Page De Service
                    </p>
                  </div>
                </div>
                <div class="service-details">
                  <div class="service-details-title">
                    Détails Du Service <span>Tout Effacer</span>
                  </div>
                  <div class="details-form">
                    <div class="duration">
                      <div>
                        <label >Durée De La Préstation</label>
                        <input
                          onChange={(e) =>
                            this.setState({ duration: e.target.value })
                          }
                          type="text"
                          name=""
                          id=""
                        />
                      </div>
                    </div>
                    <div class="duration">
                      <div>
                        <label >Ajouter Une Marge De Temps</label>
                        <input
                          onChange={(e) =>
                            this.setState({ durationError: e.target.value })
                          }
                          type="text"
                          name=""
                          id=""
                        />
                      </div>
                    </div>
                    <div class="duration">
                      <div>
                        <label >Prix Par Prestation</label>
                        <input
                          onChange={(e) =>
                            this.setState({ price: e.target.value })
                          }
                          type="text"
                          name=""
                          id=""
                        />
                      </div>
                    </div>

                    <div class="add-btn">Ajouter Un Nouveau Service</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default AddService;
