import React from "react";

const landingInfo = (props) => {
  return (
    <div class="container">
      <div class="partner-cards-section">
        <div class="cards">
          <div class="card">
            <img src="images/undraw_people_tax5.png" alt="" />
            <p>Soyez visible pour tout le monde</p>
          </div>
          <div class="card">
            <img src="images/undraw_stepping_up_g6oo.png" alt="" />
            <p>Gagnez plus de clients et augmentez vos bénéfices</p>
          </div>
          <div class="card">
            <img src="images/undraw_Booking_re_gw4j.png" alt="" />
            <p>
            Recevez des réservations et gerez les en quelques cliques
            </p>
          </div>
          <div class="card">
            <img src="images/undraw_add_to_cart_vkjp.png" alt="" />
            <p>Vendez directement vos produits grâce à notre shop en ligne</p>
          </div>
          <div class="card">
            <img src="images/undraw_checking_boxes_2ibd.png" alt="" />
            <p>Recevez des réservationset gérez les en un seul clique</p>
          </div>
          <div class="card">
            <img src="images/undraw_reviews_lp8w.png" alt="" />
            <p>
              Sachez à tout moment ce que pensent vos clients de la qualité de
              vos services
            </p>
          </div>
        </div>
      </div>
     {/* <div class="partner-testimony-section">
        <h3>Témoignage</h3>
        <div class="partner-testimony-cards">
          <div class="card">
            <div class="avatar">
              <img src="images/Group 11614.png" alt="" />
            </div>
            <div class="name-stars">
              <span>aimane</span>
              <img src="images/Group 11617.png" alt="" />
            </div>
            <div class="desc">
              Vendez directement vos produits grâce à notre shop en ligne
            </div>
          </div>
          <div class="card">
            <div class="avatar">
              <img src="images/Group 11613.png" alt="" />
            </div>
            <div class="name-stars">
              <span>aimane</span>
              <img src="images/Group 11617.png" alt="" />
            </div>
            <div class="desc">
              Vendez directement vos produits grâce à notre shop en ligne
            </div>
          </div>
          <div class="card">
            <div class="avatar">
              <img src="images/Group 11612.png" alt="" />
            </div>
            <div class="name-stars">
              <span>aimane</span>
              <img src="images/Group 11617.png" alt="" />
            </div>
            <div class="desc">
              Vendez directement vos produits grâce à notre shop en ligne
            </div>
          </div>
        </div>
      </div>
  */  } </div>
  );
};

export default landingInfo;
