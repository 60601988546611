import Header from "../Components/header";
import React, { Component } from "react";
import LandingContent from "../Components/signUp/landingContent";
import PartnerForm from "../Components/signUp/partnerForm";
import LandingInfo from "../Components/signUp/landingInfo";
import Footer from "../Components/footer";
import NextForm from "../Components/signUp/nextForm";
import Confirmation from "../Components/signUp/verification";
import LoginForm from "../Components/loginForm";
import {
  getModule,
  openNotificationWithIcon,
  postModule
} from "../utils/apiService";
import { Link } from "react-router-dom";
import EmailForm from "../Components/resetPassword/emailForm";
import EmailVerfication from "../Components/resetPassword/emailVerification";
import ChangePassword from "../Components/resetPassword/changePassword";

class Reset extends Component {
  state = {
    email: "",
    token: "",
    verification: false,
    password_box: false
  };
  changeHandler(key, value) {
    const updatedData = this.state;
    updatedData[key] = value;
    this.setState(updatedData);
  }
  logHandler(e) {
    e.preventDefault();
    getModule({ module: `auth/reset-password?email=${this.state.email}` })
      .then((response) => {
        this.setState({ verification: true });
      })
      .catch((e) => {
        this.setState({ error: e.response.message });
      });
  }

  render() {
    return (
      <>
        <div class="partner-page-container">
          <div class="partner-landing">
            <div class="container">
              <Header />
              <div className="partner-landing-content">
                <LandingContent />
                <div class="partner-form">
                  {this.state.password_box ? (
                    <ChangePassword
                      email={this.state.email}
                      token={this.state.token}
                    />
                  ) : this.state.verification ? (
                    <EmailVerfication
                      token={this.state.token}
                      changeHandler={this.changeHandler.bind(this)}
                      email={this.state.email}
                    />
                  ) : (
                    <EmailForm
                      error={this.state.error}
                      logHandler={this.logHandler.bind(this)}
                      changeHandler={this.changeHandler.bind(this)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <LandingInfo />
        </div>
        <Footer />
      </>
    );
  }
}

export default Reset;
