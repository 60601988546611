import React, { useState } from "react";
import { getModule, openNotificationWithIcon, postModule } from "../../utils/apiService";

const Confirmation = (props) => {
  const [token, setToken] = useState("");
  const verificationHandler = (e) => {
    e.preventDefault();
    getModule({
      module: `auth/activate?email=${props.email}&token=${token}`
    }).then((rsp) => {
      if (rsp.status === 200) {
        openNotificationWithIcon(
          "success",
          "Congrats",
          "Your Email Has Been Activated"
        );
        window.location.href = "/";
      } else
        openNotificationWithIcon("warning", "Wrong Code", "Please Check Again");
    });
  };

  const resend = (e) => {
    postModule({module : "auth/resend-email" , data : { "email" :props.email }}).then(rsp => {if(rsp.successful) {
      openNotificationWithIcon("success" , "success" , "Email has been sent!")
    }})
  }
  return (
    <div class="partner-form">
      <form>
        <div class="title">
          <div class="title-img">
            <img src="images/Path 42137.png" alt="" />
            <img src="images/Group 11394.png" alt="" class="lock" />
          </div>
          <h4>Vérifiez votre email</h4>
        </div>
        <p class="desc">
          un code de vérification vous a été envoyé par email à{props.email}
          (Vérifiez vos spam).
        </p>
        <input
          type="text"
          name=""
          id=""
          placeholder="entrer le code de vérification"
          onChange={(e) => setToken(e.target.value)}
        />
        <div onClick={resend} class="didnt-receive-email">Vous n’avez pas reçu d’e-mail ?</div>
        <div class="btns">
          <i class="fas fa-caret-left"></i>
          <button onClick={verificationHandler}>S’inscrire</button>
        </div>
      </form>
    </div>

  
  );
};

export default Confirmation;
