import React from "react";
import { Link, useHistory } from "react-router-dom";

const LoggedHeader = (props) => {
  const history = useHistory();

  return (
    <header>
      <div className="container">
        <div className="logo">
          <Link to="/">
          <img src="images/logo.png" alt="" />

          </Link>
        </div>
        <div className="avatar">
          <img src="images/image1-1.png" alt="" />
          <div className="full-name">{localStorage.getItem("fullname")}</div>
          <div className="logout-profile">
            <Link style={{ color: "white" }} to="/Profil">
              <span>Profil</span>
            </Link>
            <span
              onClick={() => {
                localStorage.removeItem("token");
                history.replace("/");
                history.go(0);
              }}
            >
              Déconnexions
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LoggedHeader;
