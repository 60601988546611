import React, { useState } from "react";
import { useHistory } from "react-router";
import { openNotificationWithIcon, postModule } from "../../utils/apiService";

const ChangePassword = (props) => {
  const [passwords, setPassword] = useState({});
  const router = useHistory();

  const changeHandler = (e) => {
    e.preventDefault();

    if (passwords.old === passwords.new) {
      postModule({
        module: "auth/reset-password",
        data: {
          email: props.email,
          token: props.token,
          password: passwords.new
        }
      })
        .then((response) => {

          if (response.status == 200) {
          window.location.href = "/" ;
          } else if (response.status == 422) {
            openNotificationWithIcon(
              "warning",
              "Wrong data",
              "Enter a valid password"
            );
          } else {
            openNotificationWithIcon(
              "warning",
              "Error",
              "Something went wrong"
            );
          }
        })
        .catch(() => {
          openNotificationWithIcon("error", "Error", "Something went wrong");
        });
    }
  };

  return (
    <form>
      <div class="title">
        <h4>Rest Password</h4>
      </div>
      <input
        onChange={(e) => setPassword({ ...passwords, new: e.target.value })}
        type="password"
        placeholder="New Password"
      />
      <input
        onChange={(e) => setPassword({ ...passwords, old: e.target.value })}
        type="password"
        placeholder="Confirm New Password"
      />
      <button onClick={(e) => changeHandler(e)}>Reset</button>
      <br /> <br />
    </form>
  );
};

export default ChangePassword;
