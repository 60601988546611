import React from "react";
import Item from "./shop";
import './index.css'
const Shop = (props) => {
  return (
    <div className="yourShop-container">
      <div className="products-card">
        {props.products.map((pr, index) => (
          <Item {...pr} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Shop;
