import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/footer";
import LoggedHeader from "../Components/loggedHeader";
import Nav from "../Components/nav";
import { BASE, BASEAPI, getModule, openNotificationWithIcon, postModule } from "../utils/apiService";
// multiple-imgs
import './editService.css'


class EditService extends Component {
  state = {
    images: []
  };
  async componentDidMount() {
    const query = this.props.match.params.id;
    await getModule({ module: `services/${query}` }).then((rsp) => {
      let data = rsp.data.data;
      delete data["createdAt"];
      delete data["updatedAt"];
      delete data["roomId"];


      this.setState({ ...data });
    });
  }

  submitHandler(e) {
    e.preventDefault();
    let updatedState = this.state;
    delete updatedState["images"];
    delete updatedState["id"];
    delete updatedState["userId"];

    const formdata = new FormData();
    Object.keys(this.state).map((key) => {
      formdata.append(key, this.state[key]);
    });
    const token = localStorage.getItem("token");
    const query = this.props.match.params.id;

    axios
      .patch( BASEAPI + "/services/" + query, updatedState, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"

          // 'content-type' : 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          openNotificationWithIcon(
            "success",
            "success",
            "your service has been updated"
          );
          window.location.reload();
        }
      })
      .catch(error => {
        //conflict
        if (error.response.status === 409) {
          alert('Sorry, Titre du Service already exist!!!')
        }
        // error.response.data.message.map(m => openNotificationWithIcon("default" , "error" , m) )
      }
      );
  }

  submitImg(img) {
    const formdata = new FormData();
    formdata.append("image", img)

    const query = this.props.match.params.id;
    postModule({module : `/services/${query}/images` , data : formdata})
      .then((response) => {
        if (response.status === 201) {
          openNotificationWithIcon(
            "success",
            "success",
            "your image has been added"
          );
        }
      })
      .catch(error => {
        console.log(error.message)
      }
      );
  }
  //delete img based on the id
  deleteImg(e, id) {
    e.preventDefault()
    
      const formdata = new FormData();
      formdata.append("id", id)

      const token = localStorage.getItem("token");
      
      axios.delete(BASEAPI +`/services/images/${id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          if (response.status === 200) {
            openNotificationWithIcon(
              "success",
              "success",
              "the img has been deleted"
            );
          }

        })
        .catch(error => {
          console.log(error.message)
        }
        ); 
  }
  // multiple imgs

  handleFileInput(e) {
    const fileInput = document.querySelector('input[type=file]')
    fileInput.click()
  }

  handleInputChange(e) {
    const span = document.querySelector('.edit-service-img span')
    span.style.backgroundImage = `url("${URL.createObjectURL(e.target.files[0])}")`
    span.textContent = ''

    this.submitImg(e.target.files[0])

  }
  render() {

    return (
      <>
        <div className="accueil-container">
          <LoggedHeader />
          <Nav />
          <div className="services-container">
            <div className="add-service-form">
              <form>
                <div className="cancel-post-btn">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push("/");
                    }}
                    className="cancel"
                  >
                    annuler
                  </button>

                  <button onClick={(e) => this.submitHandler(e)} className="save">
                    enregistrer
                  </button>
                </div>
                <div className="general-info">
                  <div className="general-info-title">Informations Générales</div>
                  <div className="info">
                    <div className="form-info">
                      <div className="service-title">
                        <label >
                          Titre Du Service <span>30</span>
                        </label>
                        <input
                          value={this.state.title}
                          onChange={(e) =>
                            this.setState({ title: e.target.value })
                          }
                          type="text"
                          name=""
                          id=""
                        />
                      </div>
                      <div className="untitled-service">
                        <label >
                          Description du service (Dites un peu plus sur votre service) <span>70</span>
                        </label>
                        <textarea value={this.state.description}
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          cols="30" rows="10"
                        ></textarea>
                      </div>

                    </div>
                    {/* // multiple imgs */}
                    <div className="principal-img">
                      <img src={this.state.images[0] ? BASEAPI + "/services/images/" + this.state.images[0].filename : ''
                      }
                        alt="principal img" />
                    </div>
                  </div>
                  <div className="desc">
                    <div>Service Category</div>
                    <br />
                    <select value={this.state.category} onChange={(e) =>
                      this.setState({
                        ...this.state,
                        category: e.target.value
                      })
                    }
                    >
                      <option>Category</option>
                      <option value="COIFFURE">COIFFURE</option>
                      <option value="TATOUAGE">TATOUAGE</option>
                      <option value="HAMMAM">HAMMAM</option>
                      <option value="EPILATION_ET_SOINS">EPILATION ET SOINS</option>
                      <option value="ONGLERIE">ONGLERIE</option>
                    </select>
                  </div>

                  <div className="desc">
                    <div>Description Et Medias (Dites un peu plus sur votre service)</div>
                    <p>
                      Ajoutez Plus De Texte Et D’images Pour Mettre En Valeur
                      Votre Page De Service
                    </p>
                  </div>
                  <div className="availabel-imgs">
                    {
                      //list all service imgs
                      this.state.images.map((img, index) =>
                        <div className="img-container" key={index}>
                          <img src={BASEAPI + "/services/images/" + img.filename} alt="service picture" />
                          <span onClick={(e) => this.deleteImg(e, img.id)}></span>
                        </div>
                      )
                    }
                    <div className="edit-service-img">
                      <input type="file" accept="image/*" onChange={e => this.handleInputChange(e)} />
                      <span onClick={e => this.handleFileInput(e)}></span>
                    </div>
                  </div>
                </div>
                <div className="service-details">
                  <div className="service-details-title">
                    Détails Du Service <span>Tout Effacer</span>
                  </div>
                  <div className="details-form">
                    <div className="duration">
                      <div>
                        <label >Durée De La Préstation</label>
                        <input
                          value={this.state.duration}
                          onChange={(e) =>
                            this.setState({ duration: e.target.value })
                          }
                          type="text"
                          name=""
                          id=""
                        />
                      </div>
                    </div>
                    <div className="duration">
                      <div>
                        <label>Ajouter Une Marge De Temps</label>
                        <input
                          value={this.state.durationError}
                          onChange={(e) =>
                            this.setState({ durationError: e.target.value })
                          }
                          type="text"
                          name=""
                          id=""
                        />
                      </div>
                    </div>
                    <div className="duration">
                      <div>
                        <label >Prix Par Prestation</label>
                        <input
                          value={this.state.price}
                          onChange={(e) =>
                            this.setState({ price: e.target.value })
                          }
                          type="text"
                          name=""
                          id=""
                        />
                      </div>
                    </div>

                    <div className="add-btn">Ajouter Un Nouveau Service</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default EditService;
