import React, { Component } from "react";
import Avis from "../Components/Avis";
import Footer from "../Components/footer";
import LoggedHeader from "../Components/loggedHeader";
import Nav from "../Components/nav";

class AvisContainer extends Component {
  render() {
    return (
      <>
        <div class="accueil-container">
          <LoggedHeader />
          <Nav />
          <Avis />
        </div>
        <Footer />
      </>
    );
  }
}

export default AvisContainer;
